import { green, red } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    dialogToolbar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    labels: {
        display: 'flex',
        marginTop: '20px',
        '& svg': {
            marginTop: '-1px',
            marginLeft: '5px',
        },
    },
    green: {
        color: green[600],
    },
    red: {
        color: red[600],
    },
}));
