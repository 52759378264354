import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'grid',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: theme.palette.common.white,
    },
    container2: {
        padding: '25px',
        margin: '0 20px',
        borderRadius: 10,
        border: '1px solid',
        borderColor: theme.palette.primary.main,
    },
    content: {
        maxWidth: '500px',
        borderRadius: 10,
        backgroundColor: 'white',
        padding: '20px',
    },
    logo: {
        marginBottom: '10px',
    },
}));
