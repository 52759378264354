import Brightness1Icon from '@mui/icons-material/Brightness1';
import Mic from '@mui/icons-material/Mic';
import VideoCam from '@mui/icons-material/Videocam';
import { Tooltip } from '@mui/material';
import { green, orange, red } from '@mui/material/colors';
import { useEffect, useState } from 'react';

import QualityTestLowDialog from 'components/QualityTestLowDialog';
import { useAuth } from 'core/auth';
import { logger } from 'helpers';
import type { Nilable } from 'types/helpers';
import type { OtPublisher, OtPublisherStats } from 'types/openTok';
import useStyles from './styles';

export type QualityIndicatorProps = {
    publisher: Nilable<OtPublisher>;
};

export function QualityIndicator({ publisher }: QualityIndicatorProps) {
    const classes = useStyles();

    const { user, setUser } = useAuth();
    const [firstSetDone, setFirstSetDone] = useState(false);
    const [testDone, setTestDone] = useState(false);
    const [showQualityLowDialog, setShowQualityLowDialog] = useState(false);
    const [prevStats, setPrevStats] = useState<OtPublisherStats>();
    const [audioColor, setAudioColor] = useState<string>(green['A700']);
    const [videoColor, setVideoColor] = useState<string>(green['A700']);

    const getColorFromMos = (mos = 0) => {
        if (mos > 3.8) return green['A700'];
        if (mos > 3.1) return green['A200'];
        if (mos > 2.4) return orange['A700'];
        if (mos > 1.7) return orange['A700'];

        return red['A700'];
    };

    const getColorFromBpsAudio = (bps: number) => {
        const kbps = bps / 1000;
        if (kbps > 30) {
            return green['A700'];
        }
        if (kbps > 25) {
            return green['A200'];
        }
        return orange['A700'];
    };

    const getColorFromBpsVideo = (bps: number, resolution: string) => {
        const kbps = bps / 1000;
        if (
            (resolution === '1920x1080' && kbps > 3500) ||
            (resolution === '1280x720' && kbps > 1000) ||
            (resolution === '640x480' && kbps > 600) ||
            (resolution === '352x288' && kbps > 300) ||
            (resolution === '320x240' && kbps > 300)
        ) {
            return green['A700'];
        }

        if (
            (resolution === '1280x720' && kbps > 350) ||
            (resolution === '640x480' && kbps > 250) ||
            (resolution === '352x288' && kbps > 150) ||
            (resolution === '320x240' && kbps > 150)
        ) {
            return green['A200'];
        }
        return orange['A700'];
    };

    const handleQualityLowDialogClose = () => {
        setShowQualityLowDialog(false);
    };

    useEffect(() => {
        if (testDone && user.defaultSettings.publishVideo && videoColor === orange['A700']) {
            setShowQualityLowDialog(true);
        }
        setTestDone(false);
    }, [testDone, user.defaultSettings.publishVideo, videoColor]);

    useEffect(() => {
        if (firstSetDone && publisher) {
            const timer = setInterval(() => {
                publisher.getStats((error, statsArray) => {
                    if (error) {
                        return logger.warn(error);
                    }
                    statsArray?.forEach((statsObj) => {
                        // eslint-disable-next-line @typescript-eslint/no-explicit-any
                        let prevStatsObj: any;
                        if (statsObj.connectionId && prevStats) {
                            // ???
                            prevStatsObj = prevStats[statsObj.connectionId as keyof OtPublisherStats];
                            logger.debug('stats for connection', statsObj.connectionId);
                        } else {
                            prevStatsObj = prevStats;
                        }
                        const stats = statsObj.stats as OtPublisherStats;
                        const videoWidth = publisher.videoWidth();
                        const videoHeight = publisher.videoHeight();
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                        if (prevStatsObj?.video && videoWidth && videoHeight) {
                            const resolution = `${videoWidth}x${videoHeight}`;
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            const videoBitRate = 8 * (stats.video.bytesSent - prevStatsObj.video.bytesSent);
                            logger.debug('video bit rate: ', videoBitRate, 'bps');
                            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                            const audioBitRate = 8 * (stats.audio.bytesSent - prevStatsObj.audio.bytesSent);
                            logger.debug('audio bit rate: ', audioBitRate, 'bps');
                            setVideoColor(getColorFromBpsVideo(videoBitRate, resolution));
                            setAudioColor(getColorFromBpsAudio(audioBitRate));
                            setTestDone(true);
                        }
                        if (stats.connectionId && prevStats) {
                            prevStats[stats.connectionId as keyof OtPublisherStats] = stats as never;
                        } else {
                            setPrevStats(stats);
                        }
                    });
                });
            }, 120000);
            return () => {
                clearInterval(timer);
            };
        }
    }, [firstSetDone, prevStats, publisher, user.defaultSettings.publishAudio, videoColor]);

    useEffect(() => {
        if (user && !firstSetDone) {
            setAudioColor(getColorFromMos(user.qualityTestData?.audio.mos));
            setVideoColor(getColorFromMos(user.qualityTestData?.video.mos));
            setFirstSetDone(true);
            setTestDone(true);
        }
    }, [firstSetDone, user]);

    return (
        <div className={classes.indicatorsWrapper}>
            <div className={classes.separator}></div>
            <Tooltip
                title={<span style={{ fontSize: '12px' }}>Qualité audio</span>}
                placement="top"
            >
                <div className={classes.indicator}>
                    <Mic style={{ fontSize: 24 }} />
                    <Brightness1Icon
                        style={{ fontSize: 24 }}
                        sx={{ color: audioColor }}
                    />
                </div>
            </Tooltip>
            <div className={classes.separator}></div>
            <Tooltip
                title={<span style={{ fontSize: '12px' }}>Qualité vidéo</span>}
                placement="top"
            >
                <div className={classes.indicator}>
                    <VideoCam style={{ fontSize: 24 }} />
                    <Brightness1Icon
                        style={{ fontSize: 24 }}
                        sx={{ color: videoColor }}
                    />
                </div>
            </Tooltip>
            <div className={classes.separator}></div>
            <QualityTestLowDialog
                open={showQualityLowDialog}
                onClose={handleQualityLowDialogClose}
                user={user}
                setUser={setUser}
            />
        </div>
    );
}
