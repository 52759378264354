import Chat from '@mui/icons-material/Chat';
import Mail from '@mui/icons-material/Mail';
import { Badge } from '@mui/material';
import type { MouseEventHandler } from 'react';

import { ControlButton } from 'components/ControlButton';

import useStyles from './styles';

export type ChatButtonProps = {
    onClick: MouseEventHandler;
    newMessages: boolean;
};

export function ChatButton({ onClick, newMessages }: ChatButtonProps) {
    const classes = useStyles();

    return (
        <ControlButton
            Icon={Chat}
            onClick={onClick}
            tooltip="Chat"
            className={classes.button}
            badge={
                newMessages && (
                    <Badge
                        badgeContent={<Mail fontSize="inherit" />}
                        color="error"
                        overlap="circular"
                        className={classes.badge}
                    />
                )
            }
        />
    );
}
