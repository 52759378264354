import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    meetingRoom: {
        height: '100%',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        backgroundColor: theme.palette.common.white,
        overflow: 'hidden',
        '& .OT_root': {
            zIndex: 1000,
        },
    },
    wrapper: {
        position: 'relative',
        display: 'flex',
        flex: 1,
        padding: '8px',
    },
    participantsContainer: {
        backgroundColor: 'white',
        borderTop: '1px solid',
        zIndex: 10,
        overflow: 'hidden',
        borderTopColor: theme.palette.common.white,
        transitionProperty: 'width',
        transitionDuration: '.2s',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.6, 1)',
        '&.expanded': {
            width: '230px',
            padding: '10px',
        },
        '&.shrinked': {
            width: '0',
            padding: '10px 0',
        },
    },
    container: {
        position: 'relative',
        flex: 1,
        backgroundColor: theme.palette.common.white,
        '& .ot-layout': {
            margin: '4px',
            borderRadius: '8px',
            isolation: 'isolate',
            transitionProperty: 'left right top bottom width height',
            transitionDuration: '.2s',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.6, 1)',
        },
        '& .ot-layout .OT_video-poster img, & .ot-layout .OT_video-poster svg': {
            display: 'none',
        },
        '& .ot-layout.OT_audio-only .OT_video-poster img, & .ot-layout.OT_audio-only .OT_video-poster svg': {
            display: 'block',
        },
        '& .OT_name, & .OT_audio-level-meter__audio-only-img': {
            display: 'none',
        },
        '& .OT_audio-level-meter': {
            display: 'block !important',
            background: 'none',
            width: 'initial',
            top: '5px',
            right: '5px',
            zIndex: 1,
            '&:before': {
                display: 'none',
            },
            '& svg': {
                height: '24px',
                width: '24px',
            },
        },
        '& .OT_video-poster': {
            backgroundImage: 'none',
            opacity: 1,
            display: 'flex !important',
            justifyContent: 'center',
            alignItems: 'center',
            padding: '3em',
        },
        '& .OT_video-poster > svg, & .OT_video-poster > img': {
            borderRadius: '50%',
        },
        '& .OT_video-poster > img': {
            width: '100%',
            maxWidth: '205px',
            minWidth: '90px',
        },
        '& .OT_video-poster > svg': {
            color: 'white',
            border: '2px solid',
            padding: '20px',
            width: '100%',
            maxWidth: '205px',
            minWidth: '90px',
            aspectRatio: 1,
            height: 'auto',
        },
        '& .OT_edge-bar-item': {
            top: '0 !important',
            opacity: '1 !important',
            '&.OT_mute': {
                display: 'none !important',
            },
        },
        '& .force-hidden': {
            display: 'none !important',
        },
        '& .audioLevelIndicator': {
            position: 'absolute',
            width: '33px',
            height: '33px',
            border: '1px solid black',
            borderRadius: '5px',
            overflow: 'hidden',
            right: 0,
            top: '35px',
            margin: '6px',
            zIndex: 1,
            '& img': {
                width: '100%',
                height: '100%',
            },
            '&.on': {
                opacity: 1,
                transitionProperty: 'opacity',
                transitionDuration: '0.2s',
            },
            '&.off': {
                opacity: 0,
                transitionProperty: 'opacity',
                transitionDuration: '0.5s',
            },
        },
    },
    sharingContainer: {
        padding: '4px',
        boxSizing: 'border-box',
        zIndex: 1000,
        position: 'absolute',
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        backgroundColor: theme.palette.common.white,
        transitionProperty: 'z-index opacity',
        transitionDuration: '.5s',
        transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.6, 1)',
        '& .OT_video-poster': {
            display: 'none !important',
        },
        '& > div': {
            borderRadius: '8px',
        },
        '& .ot-layout': {
            transitionProperty: 'left right top bottom width height',
            transitionDuration: '.2s',
            transitionTimingFunction: 'cubic-bezier(0.4, 0, 0.6, 1)',
        },
        '&.visible': {
            zIndex: 1001,
            opacity: 1,
        },
        '&.hidden': {
            zIndex: 0,
            opacity: 0,
        },
    },
    controlToolbar: {
        borderTop: '1px solid ',
        borderTopColor: theme.palette.common.white,
        backgroundColor: 'white',
        display: 'flex',
        flexDirection: 'row',
        zIndex: 100,
        height: '45px',
        flex: '60px 0 0',
        width: '100%',
        overflow: 'hidden',
        [theme.breakpoints.down('sm')]: {
            flex: '45px 0 0',
        },
    },
    loader: {
        backgroundColor: theme.palette.common.white,
    },
    waitingMessageWrapper: {
        position: 'absolute',
        background: 'transparent',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 10,
        [theme.breakpoints.up('md')]: {
            right: '250px',
        },
    },
}));
