/* eslint-disable no-console, @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars */

const isDev = process.env.NODE_ENV === 'development';

/**
 * A logger that logs to the console.
 * debug logs are only displayed in development mode.
 */
export const logger = {
    info: console.info.bind(console),
    warn: console.warn.bind(console),
    error: console.error.bind(console),
    debug: isDev ? console.debug.bind(console) : (..._: unknown[]) => {},
};
