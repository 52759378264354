import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import type { Publisher, PublisherProperties, Session } from '@opentok/client';
import OT from '@opentok/client';
import { useCallback, useRef, useState } from 'react';

import { useAuth } from 'core/auth';
import { useDevices, useOtSpeech } from 'core/openTok';
import { capitalize, logger, render, useAvatar } from 'helpers';
import type { OtPublisher, OtStream } from 'types/openTok';

export type StreamHandlerOption = {
    stream: OtStream;
};

export type CalculateAudioLevelOptions = {
    audioLevel: number;
};

export type PublishOptions = {
    session: Session;
    containerId: string;
    publisherOptions: PublisherProperties;
};

export function usePublisher() {
    const { user, setUser } = useAuth();
    const [isPublishing, setIsPublishing] = useState(false);
    const [pubInitialised, setPubInitialised] = useState(false);
    const [logLevel, setLogLevel] = useState(0);
    const publisherRef = useRef<OtPublisher | null>(null);
    const { deviceInfo, getDevices } = useDevices();
    const { getAvatarUrl } = useAvatar();
    const otSpeech = useOtSpeech();

    const calculateAudioLevel = useCallback(({ audioLevel }: CalculateAudioLevelOptions) => {
        let movingAvg = null;
        if (movingAvg === null || movingAvg <= audioLevel) {
            movingAvg = audioLevel;
        } else {
            movingAvg = 0.8 * movingAvg + 0.2 * audioLevel;
        }
        // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
        const currentLogLevel = Math.log(movingAvg) / Math.LN10 / 1.5 + 1;
        setLogLevel(Math.min(Math.max(currentLogLevel, 0), 1) * 100);
    }, []);

    const streamCreatedListener = useCallback(
        ({ stream }: StreamHandlerOption) => {
            //TODO understand why this type confusion
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
            otSpeech.addSubscriber(publisherRef.current as any);
            publisherRef.current?.element?.setAttribute('data-stream-id', stream.id);
            const audioLevelElement = publisherRef.current?.element?.querySelector(
                '.OT_audio-level-meter'
            ) as HTMLElement;

            if (!audioLevelElement) {
                return;
            }

            if (publisherRef.current?.stream?.hasAudio) {
                render(<MicIcon />, audioLevelElement);
            } else {
                render(<MicOffIcon />, audioLevelElement);
            }

            audioLevelElement.style.display = 'block !important';
        },
        [otSpeech]
    );

    const videoElementCreatedListener = useCallback(() => {
        const src = `${getAvatarUrl(user.firstname, user.lastname, true)}`;
        const publisherElement = publisherRef.current?.element;

        if (!publisherElement) {
            throw new Error('no OT publisher element');
        }

        const videoPosterElement = publisherElement.querySelector('.OT_video-poster');

        render(
            <img
                alt="avatar"
                src={src}
            />,
            videoPosterElement
        );
        const firstName = capitalize(user.firstname);
        const lastName = capitalize(user.lastname);

        const nameElement = publisherElement.querySelector('.OT_name') as HTMLElement;
        nameElement.innerHTML = `${firstName} ${lastName}`;
        nameElement.style.display = 'block';
        setPubInitialised(true);
    }, [getAvatarUrl, user.firstname, user.lastname]);

    const streamDestroyedListener = useCallback(
        ({ stream }: StreamHandlerOption) => {
            otSpeech.removeSubscriberByStreamId(stream?.id);
            publisherRef.current = null;
            setPubInitialised(false);
            setIsPublishing(false);
        },
        [otSpeech]
    );

    const accessAllowedListener = useCallback(() => {
        getDevices();
    }, [getDevices]);

    const accessDeniedListener = useCallback(() => {
        publisherRef.current = null;
        setPubInitialised(false);
    }, []);

    const forceMute = useCallback(() => {
        setUser({
            ...user,
            defaultSettings: { ...user.defaultSettings, publishAudio: !user.defaultSettings.publishAudio },
        });
    }, [setUser, user]);

    /**
     * Triggered when a new user join the call
     */
    const initPublisher = useCallback(
        (containerId: string, publisherOptions: PublisherProperties) => {
            let audioLevelElement = publisherRef.current?.element?.querySelector(
                '.OT_audio-level-meter'
            ) as HTMLElement;

            if (publisherRef.current) {
                if (publisherOptions.publishAudio) {
                    render(<MicIcon />, audioLevelElement);
                } else {
                    render(<MicOffIcon />, audioLevelElement);
                }
                return;
            }

            if (!containerId) {
                logger.warn('UsePublisher - Container not available');
            }

            const finalPublisherOptions: PublisherProperties = {
                ...publisherOptions,
                insertMode: 'append',
                width: '100%',
                height: '100%',
                style: {
                    buttonDisplayMode: 'auto',
                    nameDisplayMode: 'auto',
                    audioLevelDisplayMode: 'auto',
                },
                showControls: true,
            };

            publisherRef.current = OT.initPublisher(containerId, finalPublisherOptions, (err) => {
                audioLevelElement = publisherRef.current?.element?.querySelector(
                    '.OT_audio-level-meter'
                ) as HTMLElement;

                if (err) {
                    publisherRef.current = null;
                } else {
                    if (publisherOptions.publishAudio) {
                        render(<MicIcon />, audioLevelElement);
                    } else {
                        render(<MicOffIcon />, audioLevelElement);
                    }
                }
            }) as OtPublisher;

            publisherRef.current.on('muteForced', forceMute);

            publisherRef.current.on('accessAllowed', accessAllowedListener);
            publisherRef.current.on('accessDenied', accessDeniedListener);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
            publisherRef.current.on('streamCreated', streamCreatedListener as any);
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
            publisherRef.current.on('streamDestroyed', streamDestroyedListener as any);
            publisherRef.current.on('videoElementCreated', videoElementCreatedListener);
            publisherRef.current.on('audioLevelUpdated', calculateAudioLevel);

            return () => {
                publisherRef.current?.off('accessAllowed', accessAllowedListener);
                publisherRef.current?.off('accessDenied', accessDeniedListener);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
                publisherRef.current?.off('streamCreated', streamCreatedListener as any);
                // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
                publisherRef.current?.off('streamDestroyed', streamDestroyedListener as any);
                publisherRef.current?.off('videoElementCreated', videoElementCreatedListener);
                publisherRef.current?.off('audioLevelUpdated', calculateAudioLevel);
            };
        },
        [
            forceMute,
            accessAllowedListener,
            accessDeniedListener,
            streamCreatedListener,
            streamDestroyedListener,
            videoElementCreatedListener,
            calculateAudioLevel,
        ]
    );

    const destroyPublisher = useCallback(() => {
        if (!publisherRef.current) {
            return;
        }

        publisherRef.current.destroy();
    }, []);

    const publish = useCallback(
        ({ session, containerId, publisherOptions }: PublishOptions) => {
            if (!publisherRef.current) {
                initPublisher(containerId, publisherOptions);
            }

            if (session && publisherRef.current && !isPublishing) {
                return new Promise((resolve, reject) => {
                    session.publish(publisherRef.current as Publisher, (err) => {
                        if (err) {
                            setIsPublishing(false);
                            reject(err);
                        } else {
                            setIsPublishing(true);
                            resolve(publisherRef.current);
                        }
                    });
                });
            }
        },
        [initPublisher, isPublishing]
    );

    const unpublish = useCallback(
        ({ session }: Pick<PublishOptions, 'session'>) => {
            if (publisherRef.current && isPublishing) {
                session.unpublish(publisherRef.current);
                setIsPublishing(false);
                publisherRef.current = null;
            }
        },
        [isPublishing, publisherRef]
    );

    const setAudioIcon = useCallback((show: boolean) => {
        const audioLevelElement = publisherRef.current?.element?.querySelector('.OT_audio-level-meter');

        if (!audioLevelElement) {
            throw new Error('missing audio level meter element');
        }

        if (show) {
            render(<MicIcon />, audioLevelElement);
        } else {
            render(<MicOffIcon />, audioLevelElement);
        }
    }, []);

    return {
        publisher: publisherRef.current,
        initPublisher,
        destroyPublisher,
        publish,
        pubInitialised,
        logLevel,
        unpublish,
        deviceInfo,
        setAudioIcon,
    };
}
