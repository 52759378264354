import ScreenShare from '@mui/icons-material/ScreenShare';
import StopScreenShare from '@mui/icons-material/StopScreenShare';
import type { MouseEventHandler } from 'react';

import { ControlButton } from 'components/ControlButton';

export type ScreenShareButtonProps = {
    isScreensharing: boolean;
    onClick: MouseEventHandler;
    disabled?: boolean;
};

export function ScreenShareButton({ isScreensharing, onClick, disabled }: ScreenShareButtonProps) {
    return (
        <ControlButton
            Icon={isScreensharing ? StopScreenShare : ScreenShare}
            onClick={onClick}
            disabled={disabled}
            tooltip="Partage d'ecran"
        />
    );
}
