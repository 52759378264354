import { makeStyles } from '@mui/styles';

export default makeStyles(
    () => ({
        input: {
            marginRight: 8,
            flexGrow: 1,
            '& input': {
                fontSize: '16px',
            },
        },
        button: {
            height: '50px',
            width: '50px',
            flexShrink: 1,
            '& svg': {
                height: '24px',
                width: '24px',
            },
        },
    }),
    { index: 1 }
);
