import type { Session } from '@opentok/client';
import { useCallback, useEffect, useRef, useState } from 'react';

import { useAuth } from 'core/auth';
import { capitalize } from 'helpers';
import type { Nilable } from 'types/helpers';

export type UseChatOptions = {
    session: Nilable<Session>;
};

export type SendMessageOptions = {
    message: string;
};

export class Message {
    id: string;
    senderId: string;
    sender: string;
    text: string;
    date: string;

    constructor({ id, senderId, sender, text, date }: Message) {
        this.id = id;
        this.senderId = senderId;
        this.sender = sender;
        this.text = text;
        this.date = date;
    }

    toString(): string {
        return JSON.stringify(this);
    }
}

export function useChat({ session }: UseChatOptions) {
    const [open, setOpen] = useState(false);
    const [newMessages, setNewMessages] = useState(false);
    const [messages, setMessages] = useState<Message[]>([]);
    const { user, token } = useAuth();
    const magicNumber = useRef<number>(0);

    async function signal({ type, data }: { type: string; data: string }) {
        return new Promise<void>((resolve, reject) => {
            const payload = JSON.parse(JSON.stringify({ type, data })) as { type: string; data: string };

            if (session) {
                session.signal(payload, (err) => {
                    if (err) {
                        reject(err);
                    } else {
                        resolve();
                    }
                });
            }
        });
    }

    async function sendMessage({ message }: SendMessageOptions) {
        if (!message) return;

        const firstname = capitalize(user.firstname);
        const lastname = capitalize(user.lastname);
        const now = new Date();

        magicNumber.current += 1;

        const toSend = new Message({
            id: `${token.id}-${magicNumber.current}-${messages.length}`,
            senderId: user.id as string,
            sender: `${firstname} ${lastname}`,
            text: message,
            date: `${now.getHours()}:${now.getMinutes()}`,
        });
        return signal({ type: 'message', data: toSend.toString() });
    }

    const messageListener = useCallback(
        ({ data }: { data: string }) => {
            setMessages((prevMessage) => {
                const jsonData = JSON.parse(data) as Message;
                const message = new Message(jsonData);

                if (!open) {
                    setNewMessages(false);
                    setTimeout(() => setNewMessages(true), 100);
                }

                return [...prevMessage, message];
            });
        },
        [open]
    );

    const toggleChat = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    useEffect(() => {
        if (open) {
            setNewMessages(false);
        }
    }, [open]);

    useEffect(() => {
        if (session) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
            session.on('signal:message', messageListener as any);
            // session.on('signal:sip', messageListener2);
        }
        return function cleanup() {
            if (session) {
                // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
                session.off('signal:message', messageListener as any);
            }
        };
    }, [session, messageListener]);

    return { open, toggleChat, sendMessage, messages, newMessages };
}
