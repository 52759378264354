import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    timer: {
        flex: '0 0 auto',
        margin: '13px',
        color: theme.palette.primary.main,
    },
    toolbarBackground: {
        margin: 'auto',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#ffffff',
        padding: '5px 2px 5px 20px',
        overflow: 'hidden',
        '& > button': {
            margin: '0 4px',
            padding: '0',
            height: '50px',
            width: '50px',
            backgroundColor: 'rgba(45, 83, 179, 0.1)',
            background: 'none',
            overflow: 'hidden',
            [theme.breakpoints.down('sm')]: {
                height: '35px',
                width: '35px',
            },
            '&:hover': {
                backgroundColor: 'rgba(45, 83, 179, 0.1)',
                filter: 'brightness(0.8)',
            },
            '& svg': {
                color: theme.palette.primary.main,
                fontSize: '20px',
            },
            '&:disabled': {
                backgroundColor: '#d0cdcd',
            },
        },
    },
    separator: {
        display: 'inline-flex',
        backgroundColor: theme.palette.primary.main,
        width: '1px',
        height: '22px',
        top: 0,
        padding: 0,
        margin: '-8px 5px',
    },
    centeredButtons: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },

    endedButtons: {
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
    },
    rightButtons: {
        display: 'flex',
        justifyContent: 'end',
        alignItems: 'end',
        marginLeft: 'auto', // Add this line to push the buttons to the right
    },
}));
