import JwtDecoder from 'jwt-decode';
import type { QualityTestResults } from 'opentok-network-test-js/dist/NetworkTest/testQuality';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import type { Nilable } from 'types/helpers';
import { useLocalStorage } from 'usehooks-ts';

export type User = {
    id: string | null;
    userName: string;
    firstname: string;
    lastname: string;
    defaultSettings: {
        publishAudio: boolean;
        publishVideo: boolean;
        frameRate?: number;
        resolution?: string;
        audioSource?: string | null;
        videoSource?: string | null;
        audioOutput?: string;
    };
    set: boolean;
    qualityTestData?: QualityTestResults | null;
};

export type Token = {
    id: string;
    admin: boolean;
    username: string;
    firstname: string;
    lastname: string;
    isRetrieveDone: boolean;
    apiKey: string;
    sessionId: string;
    token: string;
    room?: string;
    title: string;
    startDate?: number;
    type: string;
    visioInfo: string;
};

export type AuthContextState = {
    user: User;
    setUser: Dispatch<SetStateAction<User>>;
    token: Token;
    setToken: Dispatch<SetStateAction<Token>>;
};

const AuthContext = createContext<AuthContextState>({} as AuthContextState);

const defaultUser: User = {
    id: null,
    userName: `User-${Math.floor(100000 + Math.random() * 900000)}`,
    firstname: '',
    lastname: '',
    defaultSettings: {
        publishAudio: true,
        publishVideo: true,
    },
    set: false,
};

const defaultToken: Token = {
    id: '',
    username: '',
    firstname: '',
    lastname: '',
    isRetrieveDone: false,
    apiKey: '',
    sessionId: '',
    token: '',
    title: '',
    startDate: 0,
    type: '',
    visioInfo: '',
    admin: false,
};

export type AuthProviderProps = {
    children: ReactNode;
    value?: AuthContextState;
};

export function AuthProvider({ children, value }: AuthProviderProps) {
    const [user, setUser] = useState<User>(value?.user || defaultUser);
    const [token, setToken] = useState<Token>(value?.token || defaultToken);
    const queryParams = new URLSearchParams(window.location.search);
    const { showBoundary } = useErrorBoundary();
    const urlToken = queryParams.get('t');
    const [cachedToken, persistToken] = useLocalStorage<Nilable<string>>('t', urlToken);

    useEffect(() => {
        if (urlToken) {
            window.history.pushState({}, document.title, window.location.pathname);
            persistToken(urlToken);

            return;
        }

        if (!cachedToken) {
            showBoundary({ code: 401 });
        }

        const objectInfo = JwtDecoder<Token>(cachedToken as string);

        setToken({
            id: objectInfo.id,
            admin: objectInfo?.admin,
            apiKey: objectInfo?.apiKey,
            sessionId: objectInfo?.sessionId,
            token: objectInfo?.token,
            username: objectInfo?.username.split('/')[1],
            room: objectInfo?.room,
            title: objectInfo?.title,
            type: objectInfo?.type,
            lastname: objectInfo?.lastname,
            firstname: objectInfo?.firstname,
            startDate: objectInfo?.startDate ? new Date(objectInfo?.startDate).getTime() : undefined,
            visioInfo: objectInfo?.visioInfo,
            isRetrieveDone: true,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [cachedToken, urlToken]);

    useEffect(() => {
        if (token && !user.set) {
            setUser({
                ...user,
                id: token.id,
                userName: token.username,
                firstname: token.firstname,
                lastname: token.lastname,
                set: true,
            });
        }
    }, [token, user]);

    return <AuthContext.Provider value={{ user, setUser, token, setToken }}>{children}</AuthContext.Provider>;
}

export function useAuth() {
    const userData = useContext(AuthContext);

    if (!userData) {
        throw new Error('useAuth must be used in a AuthProvider');
    }

    return userData;
}
