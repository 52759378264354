import SettingsIcon from '@mui/icons-material/Settings';
import { useToggle } from 'usehooks-ts';

import { ControlButton } from 'components/ControlButton';
import { SettingsModal } from 'components/SettingsModal';
import type { DeviceState } from 'core/openTok';
import type { Nilable } from 'types/helpers';
import type { OtPublisher } from 'types/openTok';

export type SettingsButtonProps = {
    currentPublisher: Nilable<OtPublisher>;
    deviceInfo: DeviceState;
};

export function SettingsButton({ currentPublisher, deviceInfo }: SettingsButtonProps) {
    const [isOpen, toggleOpen] = useToggle(false);

    return (
        <>
            <ControlButton
                Icon={SettingsIcon}
                onClick={toggleOpen}
                tooltip="Paramètres"
            />
            <SettingsModal
                currentPublisher={currentPublisher}
                onCloseClick={toggleOpen}
                deviceInfo={deviceInfo}
                open={isOpen}
            />
        </>
    );
}
