import 'styles/main.css';

import { ThemeProvider } from '@mui/material/styles';
import { ErrorBoundary } from 'react-error-boundary';
import { MemoryRouter } from 'react-router-dom';

import { ErrorFallback } from 'components/ErrorFallback/';
import { AuthProvider } from 'core/auth';
import { render } from 'helpers';
import { theme } from 'styles/theme';
import App from './App';

render(
    <MemoryRouter>
        <ThemeProvider theme={theme}>
            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <AuthProvider>
                    <App />
                </AuthProvider>
            </ErrorBoundary>
        </ThemeProvider>
    </MemoryRouter>,
    document.getElementById('visio-rofim')
);
