import CloseIcon from '@mui/icons-material/Close';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PhoneIcon from '@mui/icons-material/Phone';
import VolumeOff from '@mui/icons-material/VolumeOff';
import { Badge, IconButton, Tooltip, Typography } from '@mui/material';
import type { MouseEventHandler } from 'react';
import { memo, useEffect, useState } from 'react';

import { AnimatedSoundIcon } from 'components/AnimatedSoundIcon';
import type { Token } from 'core/auth';
import type { Participant } from 'core/openTok';
import useStyles from './styles';

export type ParticipantListProps = {
    participants: Participant[];
    closeClick: MouseEventHandler;
    token: Token;
    onMuteParticipant: (participant: Participant) => void;
    streamId: string;
};

export const ParticipantList = memo(
    ({ participants, closeClick, onMuteParticipant, token, streamId }: ParticipantListProps) => {
        const classes = useStyles();
        const [number, setNumber] = useState(0);

        useEffect(() => {
            if (participants && participants.length !== number) {
                setNumber(participants.length);
            }
        }, [number, participants]);

        return (
            <div>
                <Typography
                    variant="h6"
                    component="h4"
                    sx={{ mb: 1, fontSize: '20px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                    Participants
                    {number > 0 && (
                        <Badge
                            badgeContent={number}
                            color="primary"
                            className={classes.badge}
                        >
                            <PeopleAltIcon sx={{ ml: 1, fontSize: '20px' }} />
                        </Badge>
                    )}
                    <IconButton
                        sx={{ ml: 'auto' }}
                        color="inherit"
                        onClick={closeClick}
                        aria-label="close"
                        size="small"
                    >
                        <CloseIcon sx={{ fontSize: '22px' }} />
                    </IconButton>
                </Typography>
                {number > 0 && (
                    <div>
                        {participants.map((p) => (
                            <div
                                key={p.id}
                                className={classes.participant}
                                data-stream-id={`p-${p.id}`}
                                onClick={() => onMuteParticipant(p)}
                            >
                                <div className={`sound-icon ${classes.soundIcon}`}>
                                    <AnimatedSoundIcon />
                                </div>
                                <div className={classes.avatar}>
                                    {p.avatarUrl && (
                                        <img
                                            src={p.avatarUrl}
                                            alt="Avatar"
                                        />
                                    )}
                                    {!p.avatarUrl && <PhoneIcon sx={{ fontSize: '20px' }} />}
                                </div>
                                <div className={classes.name}>
                                    <span>{p.firstname}</span>
                                    <span className="lastname">{p.lastname}</span>
                                </div>
                                {token.admin && streamId !== p.id && (
                                    <div className={classes.muteWrapper}>
                                        <Tooltip
                                            title={
                                                <span style={{ fontSize: '12px' }}>Couper le son du participant</span>
                                            }
                                            placement="top"
                                        >
                                            <IconButton
                                                aria-label="mute"
                                                onClick={() => onMuteParticipant(p)}
                                            >
                                                <VolumeOff />
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                )}
            </div>
        );
    }
);
