import { IconButton, Tooltip } from '@mui/material';
import type { ComponentType, MouseEventHandler, ReactNode } from 'react';

export type ControlButtonProps = {
    Icon: ComponentType;
    tooltip: string;
    onClick?: MouseEventHandler;
    disabled?: boolean;
    className?: string;
    badge?: ReactNode;
};

export function ControlButton({ Icon, tooltip, onClick, disabled, badge, className }: ControlButtonProps) {
    return (
        <Tooltip
            title={<span style={{ fontSize: '12px' }}>{tooltip}</span>}
            placement="top"
        >
            <IconButton
                edge="start"
                color="inherit"
                aria-label="mic"
                onClick={onClick}
                disabled={disabled}
                className={className}
                size="large"
            >
                {badge}
                <Icon />
            </IconButton>
        </Tooltip>
    );
}
