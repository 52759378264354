import { Typography } from '@mui/material';

import useStyles from './styles';

export type MeetingNameProps = {
    meetingName: string | JSX.Element;
};

export function MeetingName({ meetingName }: MeetingNameProps) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <Typography
                variant="h4"
                component="h2"
            >
                {meetingName}
            </Typography>
        </div>
    );
}
