import VolumeOff from '@mui/icons-material/VolumeOff';
import type { MouseEventHandler } from 'react';

import { ControlButton } from 'components/ControlButton';

export type MuteAllButtonProps = {
    onClick: MouseEventHandler;
};

export function MuteAllButton({ onClick }: MuteAllButtonProps) {
    return (
        <ControlButton
            Icon={VolumeOff}
            onClick={onClick}
            tooltip="Couper le son de tout le monde"
        />
    );
}
