import type { AudioOutputDevice } from '@opentok/client';

import type { Optional } from 'types/helpers';

/**
 * Get the audio source device id from the list of audio output devices and the current audio source
 *
 * @param audioInputDevices - The list of audio input
 * @param currentAudioSource - The current audio source
 */
export const getAudioSourceDeviceId = (
    audioInputDevices: AudioOutputDevice[],
    currentAudioSource: MediaStreamTrack
) => {
    let toReturn: Optional<string> = '';

    if (!audioInputDevices || !currentAudioSource) {
        return toReturn;
    }
    for (let i = 0; i < audioInputDevices.length; i += 1) {
        if (audioInputDevices[i].label === currentAudioSource.label) {
            toReturn = audioInputDevices[i].deviceId || undefined;
            break;
        }
    }
    return toReturn;
};
