import { useTheme } from '@mui/material';

/**
 * Create an avatar image from the user's name
 * It use the canvas API to draw the image and return a base64 string uri
 */
export function useAvatar() {
    const theme = useTheme();

    const getAvatarUrl = (firstname: string, lastname: string, noBackground: boolean): string => {
        const colors = [
            '#1abc9c',
            '#2ecc71',
            '#3498db',
            '#9b59b6',
            '#34495e',
            '#16a085',
            '#27ae60',
            '#2980b9',
            '#8e44ad',
            '#2c3e50',
            '#f1c40f',
            '#e67e22',
            '#e74c3c',
            '#95a5a6',
            '#f39c12',
            '#d35400',
            '#c0392b',
            '#bdc3c7',
            '#7f8c8d',
        ];

        const initials = [firstname[0], lastname[0]].join('');
        const nameInitials = initials.toUpperCase();

        const charIndex = nameInitials.charCodeAt(0) - 65;
        const colorIndex = charIndex % colors.length;

        const canvas = document.createElement('canvas');
        const context = canvas.getContext('2d', { alpha: true });

        if (!context) {
            throw new Error('invalid canvas 2d context');
        }

        canvas.width = noBackground ? 200 : 400;
        canvas.height = noBackground ? 200 : 400;

        // Draw background
        context.fillStyle = colors[colorIndex];
        context.arc(noBackground ? 100 : 200, noBackground ? 100 : 200, 100, 0, 360);
        context.fill();

        // Draw text
        context.font = 'bold 100px sans-serif';
        context.fillStyle = theme.palette.common.white;
        context.textAlign = 'center';
        context.textBaseline = 'middle';
        context.fillText(nameInitials, canvas.width / 2, canvas.height / 2);

        return canvas.toDataURL('image/png');
    };

    return { getAvatarUrl };
}
