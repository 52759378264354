import CallEnd from '@mui/icons-material/CallEnd';
import { IconButton } from '@mui/material';
import type { MouseEventHandler } from 'react';

import { useMediaQuery } from 'core/mediaQuery';
import useStyles from './styles';

export type CallEndButtonProps = {
    className?: string;
    onClick: MouseEventHandler;
};

export function CallEndButton({ onClick }: CallEndButtonProps) {
    const classes = useStyles();
    const { mediaQuery } = useMediaQuery();

    return (
        <>
            {mediaQuery.moreThan >= 768 && (
                <IconButton
                    onClick={onClick}
                    sx={{ fontSize: '15px' }}
                    className={classes.danger}
                    size="large"
                    id="visio-call-end-button"
                >
                    <CallEnd
                        fontSize="inherit"
                        style={{ color: 'white' }}
                    />
                </IconButton>
            )}
            {(mediaQuery.moreThan < 768 || mediaQuery.lessThan < 768) && (
                <IconButton
                    color="inherit"
                    aria-label="end"
                    className={classes.danger}
                    onClick={onClick}
                    size="large"
                    id="visio-call-end-button"
                >
                    <CallEnd
                        fontSize="inherit"
                        style={{ color: 'white' }}
                    />
                </IconButton>
            )}
        </>
    );
}
