import AvTimerIcon from '@mui/icons-material/AvTimer';
import { useEffect, useState } from 'react';

import useStyles from './styles';

export type TimerProps = {
    startTime: number;
    className: string;
};

export function Timer({ startTime, className }: TimerProps) {
    const classes = useStyles();
    const [hours, setHours] = useState(0);
    const [minutes, setMinutes] = useState(0);
    const [seconds, setSeconds] = useState(0);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const getTime = () => {
        const time = Date.now() - new Date(startTime).getTime();

        setHours(Math.floor((time / (1000 * 60 * 60)) % 24));
        setMinutes(Math.floor((time / 1000 / 60) % 60));
        setSeconds(Math.floor((time / 1000) % 60));
    };

    useEffect(() => {
        if (startTime) {
            const interval = setInterval(() => getTime(), 1000);

            return () => clearInterval(interval);
        }
    }, [getTime, startTime]);

    return (
        <div className={`${className} ${classes.timer}`}>
            <AvTimerIcon
                sx={{ mr: 1 }}
                fontSize="small"
                className={classes.icon}
            />
            {!startTime && <span>--:--</span>}
            {startTime && (
                <>
                    {hours > 0 && (
                        <div>
                            <span>{hours < 10 ? '0' + hours.toString() : hours}: </span>
                        </div>
                    )}

                    <div>
                        <span>{minutes < 10 ? '0' + minutes.toString() : minutes}: </span>
                    </div>

                    <div>
                        <span>{seconds < 10 ? '0' + seconds.toString() : seconds}</span>
                    </div>
                </>
            )}
        </div>
    );
}
