import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    timer: {
        display: 'flex',
        fontWeight: '500',
        fontSize: '1.1em',
    },
    icon: {
        fontSize: '1.3em !important',
    },
}));
