import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    loader: {
        borderRadius: '50%',
        border: '10px solid',
        borderColor: '#f3f3f3',
        borderTopColor: theme.palette.primary.main,
        width: '80px',
        height: '80px',
        animation: 'spin 1s linear infinite',
    },
}));
