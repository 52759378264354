import Videocam from '@mui/icons-material/Videocam';
import Switch from '@mui/material/Switch';
import type { ChangeEventHandler } from 'react';
import { memo } from 'react';

import styles from './styles';

export type VideoSettingsProps = {
    hasVideo: boolean;
    onVideoChange: ChangeEventHandler;
    className: string;
};

export const VideoSettings = memo(({ hasVideo, onVideoChange, className }: VideoSettingsProps) => {
    const classes = styles();

    return (
        <div className={className}>
            <div className={classes.circle}>
                <Videocam style={{ fontSize: 24 }} />
            </div>
            <p className={classes.text}>Votre caméra</p>
            <Switch
                color="primary"
                checked={hasVideo}
                onChange={onVideoChange}
                name="VideoToggle"
            />
        </div>
    );
});
