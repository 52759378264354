import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    messageContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        borderRadius: '5px',
        padding: '10px',
        backgroundColor: theme.palette.primary.main,
        '&.self': {
            backgroundColor: theme.palette.common.white,
            color: theme.palette.primary.main,
            textAlign: 'right',
        },
    },
    sender: {
        fontStyle: 'italic',
        fontWeight: 'bold',
        fontSize: '0.8em',
        margin: '0px 5px 5px 5px',
    },
    body: {
        fontWeight: '400',
        margin: '5px',
        wordWrap: 'break-word',
        textAlign: 'left',
    },
}));
