import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    circle: {
        borderRadius: '100%',
        width: '40px',
        height: '40px',
        backgroundColor: ' rgba(45, 83, 179, 0.1)',
        color: theme.palette.primary.main,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    text: {
        fontSize: '20px',
        fontWeight: 500,
        color: theme.palette.common.black,
        width: '180px',
        margin: '0',
    },
    micContainer: {
        display: 'flex',
        flexDirection: 'column',
    },
    micLevel: {
        margin: '8px 0 0',
    },
}));
