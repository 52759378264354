import { useTheme } from '@mui/material';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { createContext, useContext, useEffect, useState } from 'react';

export type MediaQuery = {
    lessThan: number;
    moreThan: number;
};

export type MediaQueryContextState = {
    mediaQuery: MediaQuery;
    setMediaQuery: Dispatch<SetStateAction<MediaQuery>>;
};

const MediaQueryContext = createContext<MediaQueryContextState>({
    mediaQuery: {
        lessThan: 0,
        moreThan: 0,
    },
} as MediaQueryContextState);

export type MediaQueryProviderProps = {
    children: ReactNode;
    value?: MediaQueryContextState;
};

export function MediaQueryProvider({ children, value }: Readonly<MediaQueryProviderProps>) {
    const [mediaQuery, setMediaQuery] = useState<MediaQuery>();
    const {
        breakpoints: {
            values: { lg, md, sm, xl },
        },
    } = useTheme();

    useEffect(() => {
        const handleResize = (entries: ResizeObserverEntry[]) => {
            entries.forEach((entry) => {
                let lessThanValue: number;
                let moreThanValue: number;
                const width = entry.contentRect.width;
                if (width <= sm) lessThanValue = sm;
                else if (width <= md) lessThanValue = md;
                else if (width <= lg) lessThanValue = lg;
                else if (width <= xl) lessThanValue = xl;

                if (width > xl) moreThanValue = xl;
                else if (width > lg) moreThanValue = lg;
                else if (width > md) moreThanValue = md;
                else if (width > sm) moreThanValue = sm;

                setMediaQuery(() => ({
                    lessThan: lessThanValue,
                    moreThan: moreThanValue,
                }));
            });
        };

        const resizeOberver = new ResizeObserver(handleResize);

        const visioElement = document.getElementById('visio-rofim');

        if (!visioElement) {
            throw new Error('missing html element with id visio-rofim');
        }

        resizeOberver.observe(visioElement);

        return () => {
            resizeOberver.disconnect();
        };
    }, [sm, md, lg, xl]);

    return (
        <MediaQueryContext.Provider value={value || ({ mediaQuery, setMediaQuery } as MediaQueryContextState)}>
            {children}
        </MediaQueryContext.Provider>
    );
}

export function useMediaQuery() {
    const mediaQuery = useContext(MediaQueryContext);

    if (!mediaQuery) {
        throw new Error('useMediaQuery must be used in a MediaQueryProvider');
    }

    return mediaQuery;
}
