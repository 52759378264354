import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    container: {
        height: '40px',
        textAlign: 'center',
        zIndex: 99,
        backgroundColor: '#ffffff',
        padding: '0 10px',
        '& h2': {
            fontSize: '22px',
            lineHeight: '40px',
        },
    },
}));
