import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    indicatorsWrapper: {
        display: 'flex',
    },
    indicator: {
        alignSelf: 'center',
        display: 'inherit',
        color: theme.palette.primary.main,
    },
    separator: {
        alignSelf: 'center',
        display: 'inline-flex',
        backgroundColor: theme.palette.primary.main,
        width: '1px',
        height: '22px',
        top: 0,
        padding: 0,
        margin: '-8px 5px',
    },
}));
