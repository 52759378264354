import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    badge: {
        '& span': {
            fontSize: '12px',
        },
    },
    participant: {
        position: 'relative',
        display: 'flex',
        margin: '4px 0',
        borderRadius: '4px',
        backgroundColor: theme.palette.common.white,
        padding: '4px 4px 1px 4px',
        '&.on .sound-icon': {
            opacity: 1,
            transitionDuration: '0.2s',
            transitionProperty: 'opacity',
        },
        '&.off .sound-icon': {
            opacity: 0,
            transitionDuration: '0.5s',
            transitionProperty: 'opacity',
        },
        '& button': {
            margin: '0 4px',
            borderRadius: '5px',
            padding: '0',
            height: '50px',
            width: '50px',
            backgroundColor: theme.palette.primary.main,
            background: 'none',
            overflow: 'hidden',
            '&:hover': {
                backgroundColor: theme.palette.primary.main,
                filter: 'brightness(0.8)',
            },
            '& svg': {
                color: 'white',
                fontSize: '20px',
            },
        },
    },
    name: {
        display: 'flex',
        flexDirection: 'column',
        margin: '2px 2px 2px 4px',
        overflow: 'hidden',
        '& span': {
            textOverflow: 'ellipsis',
            overflow: 'hidden',
            fontSize: '0.9em',
        },
        '& span.lastname': {
            fontWeight: 'bold',
        },
    },
    soundIcon: {
        opacity: 0,
        border: '1px solid black',
        height: '19px',
        width: '19px',
        zIndex: 1,
        overflow: 'hidden',
        borderRadius: '5px',
        position: 'absolute',
        top: '-5px',
        left: '-5px',
        '& img': {
            verticalAlign: 'baseline',
            width: '100%',
            height: '100%',
        },
    },
    avatar: {
        '& img': {
            height: '36px',
        },
        '& svg': {
            height: '24px',
            width: '24px',
            padding: '6px',
            borderRadius: '50%',
            backgroundColor: 'black',
            color: 'white',
            boxSizing: 'content-box',
        },
    },
    muteWrapper: {
        flex: 1,
        display: 'flex',
        justifyContent: 'flex-end',
    },
}));
