import { green, red } from '@mui/material/colors';
import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    waitingRoomContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        gap: '20px',
        justifyContent: 'center',
        alignItems: 'center',
        backgroundColor: theme.palette.common.white,
        minHeight: '100vh',
    },
    leftSide: {
        maxWidth: '560px',
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
        margin: '10px 0',
    },
    logo: {
        margin: '0 0 40px',
    },
    boldTitle: {
        fontSize: '40px',
        fontWeight: 700,
        color: theme.palette.common.black,
        margin: '20px 0 0 0',
    },
    subTitle: {
        fontSize: '25px',
        fontWeight: 700,
        color: theme.palette.common.black,
        margin: '5px 0',
    },
    subText: {
        fontSize: '18px',
        fontWeight: 300,
        color: theme.palette.common.black,
        margin: '20px 0',
    },
    text: {
        fontSize: '22px',
        fontWeight: 300,
        color: theme.palette.common.black,
        margin: '10px 0',
    },
    rightSide: {
        display: 'flex',
        flexDirection: 'column',
        maxWidth: '340px',
        width: '100%',
        padding: '0 20px 20px',
        background: 'white',
        borderRadius: '10px',
    },
    formControl: {
        width: '100%',
    },
    form: {
        width: '100%',
    },
    mediaSources: {
        margin: '10px 0 20px',
        display: 'flex',
        flexDirection: 'column',
        '& label': {
            fontSize: '16px',
        },
    },
    waitingRoomVideoPreview: {
        margin: '20px 0px',
        height: '220px',
        '&>div': {
            borderRadius: '10px',
            isolation: 'isolate',
        },
        '& .OT_name, & .OT_audio-level-meter__audio-only-img': {
            display: 'none',
        },
        '& .OT_audio-level-meter': {
            display: 'block !important',
            background: 'none',
            width: 'initial',
            top: '5px',
            right: '5px',
            zIndex: 1,
            '&:before': {
                display: 'none',
            },
            '& svg': {
                height: '24px',
                width: '24px',
            },
        },
        '& .OT_video-poster > img': {
            height: '120px',
            width: '120px',
            borderRadius: '50%',
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },
        '& .OT_video-poster': {
            backgroundImage: 'none',
            opacity: 1,
        },
        '& .OT_edge-bar-item': {
            top: '0 !important',
            opacity: '1 !important',
            '&.OT_mute': {
                display: 'none !important',
            },
        },
    },
    deviceContainer: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
    },
    deviceSettings: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        margin: '10px 0',
    },
    select: {
        fontSize: '16px !important',
    },
    flex: {
        display: 'flex',
    },
    root: {
        width: '20%',
    },
    green: {
        color: green[600],
    },
    red: {
        color: red[600],
    },
    labels: {
        display: 'flex',
        '& > svg': {
            marginTop: '-1px',
            marginLeft: '5px',
        },
    },
    loader: {
        backgroundColor: theme.palette.common.white,
        opacity: 0.5,
        position: 'absolute',
        top: 0,
    },
}));
