import { makeStyles } from '@mui/styles';

export default makeStyles((theme) => ({
    button: {
        position: 'relative',
        overflow: 'visible !important',
        color: theme.palette.primary.main,
    },
    badge: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        position: 'absolute !important' as any,
        top: '5px',
        right: '3px',
        '& span': {
            padding: '3px',
            border: '1px solid white',
            height: '22px',
            borderRadius: '12px',
            animation: 'bounceZoomIn 1s linear',
        },
        '& span svg': {
            color: 'white',
            fontSize: '15px !important',
        },
    },
}));
