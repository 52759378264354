import { SendOutlined } from '@mui/icons-material';
import { IconButton, TextField } from '@mui/material';
import type { ChangeEvent, FormEvent } from 'react';
import { useState } from 'react';

import useStyles from './styles';

export type ChatInputProps = {
    className?: string;
    sendMessage: (message: string) => void;
};

function ChatInput({ className, sendMessage }: ChatInputProps) {
    const classes = useStyles();
    const [text, setText] = useState('');

    function handleSubmit(e: FormEvent) {
        if (e) {
            e.preventDefault();
        }
        sendMessage(text);
        /* const message = new Message(user, text, isApproved);
    mMessage.send({ message }); */

        setText('');
    }

    function handleOnChange(event: ChangeEvent<HTMLInputElement>) {
        setText(event.target.value);
    }

    return (
        <form
            className={className}
            onSubmit={handleSubmit}
        >
            <TextField
                variant="standard"
                value={text}
                onChange={handleOnChange}
                className={classes.input}
            />
            <IconButton
                type="submit"
                size="large"
                className={classes.button}
            >
                <SendOutlined></SendOutlined>
            </IconButton>
        </form>
    );
}
export { ChatInput };
