import CloseIcon from '@mui/icons-material/Close';
import Error from '@mui/icons-material/Error';
import { IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';

import useStyles from './styles';

export type SimpleDialogProps = {
    selectedValue?: string;
    onClose: (value?: string) => void;
    open?: boolean;
};

export const SimpleDialog = ({ selectedValue, onClose, open }: SimpleDialogProps) => {
    const classes = useStyles();
    const handleClose = () => onClose(selectedValue);

    return (
        <Dialog
            container={() => document.getElementById('visio-rofim')}
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={!!open}
        >
            <DialogTitle id="simple-dialog-title">
                <div className={classes.dialogToolbar}>
                    <Typography sx={{ fontSize: '22px' }}>
                        <Error
                            className={classes.red}
                            sx={{ margin: '0 7px -4px 0' }}
                        />
                        Attention !
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={classes.labels}>La qualité de votre connexion semble être dégradée.</div>
                <div className={classes.labels}>Vous ne pouvez pas participer à la vidéo conférence.</div>
            </DialogContent>
        </Dialog>
    );
};

export default SimpleDialog;
