import type { Session } from '@opentok/client';
import type { MouseEventHandler } from 'react';

import { CallEndButton } from 'components/CallEndButton';
import { ChatButton } from 'components/ChatButton';
import { MicButton } from 'components/MicButton';
import { MuteAllButton } from 'components/MuteAllButton';
import { ParticipantsButton } from 'components/ParticipantsButton';
import { QualityIndicator } from 'components/QualityIndicator';
import { ScreenShare } from 'components/ScreenShare';
import { SettingsButton } from 'components/SettingsButton';
import { Timer } from 'components/Timer';
import { VideoButton } from 'components/VideoButton';
import { useMediaQuery } from 'core/mediaQuery';
import type { DeviceState } from 'core/openTok';
import type { Nilable } from 'types/helpers';
import type { OtPublisher } from 'types/openTok';
import useStyles from './styles';

export type ControlToolBarProps = {
    className: string;
    hasAudio: boolean;
    hasVideo: boolean;
    isAdmin: boolean;
    handleMicButtonClick: MouseEventHandler;
    handleVideoButtonClick: MouseEventHandler;
    handleCallEndButtonClick: MouseEventHandler;
    handleToggleChat: MouseEventHandler;
    handleMuteAll: MouseEventHandler;
    newMessages: boolean;
    currentSession: Nilable<Session>;
    currentPublisher: Nilable<OtPublisher>;
    sharingContainer: Nilable<HTMLDivElement>;
    incomingSharing: boolean;
    handleShareClick: (value: boolean) => void;
    handleParticipantsClick: MouseEventHandler;
    isParticipantsShowing: boolean;
    startTime: Nilable<number>;
    publisher: Nilable<OtPublisher>;
    deviceInfo: DeviceState;
    buttonClass: string;
};

export const ControlToolBar = ({
    className,
    hasAudio,
    hasVideo,
    isAdmin,
    handleMicButtonClick,
    handleVideoButtonClick,
    handleCallEndButtonClick,
    handleToggleChat,
    handleMuteAll,
    newMessages,
    currentSession,
    currentPublisher,
    sharingContainer,
    incomingSharing,
    handleShareClick,
    handleParticipantsClick,
    isParticipantsShowing,
    startTime,
    publisher,
    deviceInfo,
    buttonClass,
}: ControlToolBarProps) => {
    // This bar should include mic,camera, chat, screenshare, settings, endCall
    const classes = useStyles();
    const { mediaQuery } = useMediaQuery();

    return (
        <div className={className}>
            {startTime && (
                <Timer
                    startTime={startTime}
                    className={classes.timer}
                />
            )}
            {mediaQuery.moreThan >= 768 && <QualityIndicator publisher={publisher} />}
            <div className={classes.toolbarBackground}>
                <ScreenShare
                    currentSession={currentSession}
                    sharingContainer={sharingContainer}
                    handleShareClick={handleShareClick}
                    incomingSharing={incomingSharing}
                />
                <MicButton
                    hasAudio={hasAudio}
                    onClick={handleMicButtonClick}
                />
                <CallEndButton onClick={handleCallEndButtonClick} />
                <VideoButton
                    hasVideo={hasVideo}
                    onClick={handleVideoButtonClick}
                />
                {mediaQuery.moreThan >= 768 && (
                    <>
                        {isAdmin && (
                            <>
                                <MuteAllButton onClick={handleMuteAll} />
                            </>
                        )}
                    </>
                )}
            </div>
            <div className={classes.rightButtons}>
                {mediaQuery.moreThan >= 768 && (
                    <div className={`${classes.centeredButtons} ${classes.toolbarBackground}`}>
                        {isAdmin && (
                            <>
                                <ParticipantsButton
                                    onClick={handleParticipantsClick}
                                    isParticipantsShowing={isParticipantsShowing}
                                />
                                <ChatButton
                                    onClick={handleToggleChat}
                                    newMessages={newMessages}
                                />
                                <SettingsButton
                                    currentPublisher={currentPublisher}
                                    deviceInfo={deviceInfo}
                                />
                            </>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
