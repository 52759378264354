import VideoCam from '@mui/icons-material/Videocam';
import VideoCamOff from '@mui/icons-material/VideocamOff';
import type { MouseEventHandler } from 'react';

import { ControlButton } from 'components/ControlButton';

export type VideoButtonProps = {
    hasVideo: boolean;
    onClick: MouseEventHandler;
};

export function VideoButton({ hasVideo, onClick }: VideoButtonProps) {
    return (
        <ControlButton
            Icon={hasVideo ? VideoCam : VideoCamOff}
            onClick={onClick}
            tooltip="Video"
        />
    );
}
