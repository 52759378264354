import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';
import { useEffect, useState } from 'react';

import useStyles from './styles';

export type SimpleDialogProps = {
    onClose?: () => void;
    open?: boolean;
};

export const SimpleDialog = ({ onClose, open }: SimpleDialogProps) => {
    const classes = useStyles();

    const [progress, setProgress] = useState(0);

    const handleClose = (_: unknown, reason: string) => {
        if (reason && reason === 'backdropClick') return;
        onClose && onClose();
    };

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= 100) clearInterval(timer);
                return prevProgress >= 100 ? 100 : prevProgress + 1;
            });
        }, 80);
        return () => {
            clearInterval(timer);
        };
    }, []);

    return (
        <Dialog
            container={() => document.getElementById('visio-rofim')}
            aria-labelledby="simple-dialog-title"
            onClose={handleClose}
            open={!!open}
        >
            <DialogTitle id="simple-dialog-title">
                <div className={classes.dialogToolbar}>
                    <Typography sx={{ fontSize: '22px' }}>Analyse de la connexion en cours...</Typography>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={classes.labels}>
                    Cette analyse permet d'ajuster au mieux les paramètres de connexion à la vidéo conférence.
                </div>
                <Box sx={{ display: 'flex', alignItems: 'center', my: 3 }}>
                    <Box sx={{ width: '100%', mr: 1 }}>
                        <LinearProgress
                            variant="determinate"
                            value={progress}
                        />
                    </Box>
                    <Box sx={{ minWidth: 35 }}>
                        <Typography
                            variant="body2"
                            sx={{ fontSize: '14px' }}
                            color="text.secondary"
                        >{`${Math.round(progress)}%`}</Typography>
                    </Box>
                </Box>
            </DialogContent>
        </Dialog>
    );
};

export default SimpleDialog;
