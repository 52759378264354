import { makeStyles } from '@mui/styles';

const size = '200px';

export default makeStyles((theme) => ({
    container: {
        position: 'relative',
        borderRadius: '8px',
        transitionProperty: 'width, height, scale, transform, box-shadow',
        transitionDuration: '0.3s',
        overflow: 'hidden',
        '& .OT_edge-bar-item, .OT_audio-level-meter': {
            display: 'none !important',
        },
        '& .OT_video-poster': {
            justifyContent: 'center',
            alignItems: 'center',
            display: 'flex !important',
            backgroundImage: 'none',
        },
    },
    fullWidth: {
        width: '100% !important',
    },
    alone: {
        transform: 'translate(0, 0) !important',
        width: '100%',
        height: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 999,
        '& .OT_video-poster > img': {
            width: '100%',
            maxWidth: '205px',
            minWidth: '90px',
        },
    },
    dnd: {
        position: 'absolute',
        cursor: 'grab',
        width: size,
        height: size,
        zIndex: 1001,
        borderWidth: '0.1em',
        borderColor: theme.palette.grey[300],
        borderStyle: 'solid',
        boxShadow: theme.shadows[2],
        display: 'none',
        [theme.breakpoints.up('md')]: {
            display: 'block',
        },
        '&:hover': {
            width: `calc(${size} * 1.02)`,
            height: `calc(${size} * 1.02)`,
            boxShadow: theme.shadows[4],
        },
        '&:active': {
            cursor: 'grabbing',
            width: `calc(${size} * 1.02)`,
            height: `calc(${size} * 1.02)`,
            boxShadow: theme.shadows[4],
            transition: 'none',
        },
        '& .OT_video-poster > img': {
            width: '60%',
            height: '60%',
            pointerEvents: 'none',
        },
    },
    streaming: {
        '& .OT_video-poster': {
            display: 'none !important',
        },
    },
    hidden: {
        display: 'none',
    },
    icon: {
        position: 'absolute',
        top: '4px',
        left: '4px',
        zIndex: 1001,
        color: theme.palette.common.white,
    },
}));
