import { makeStyles } from '@mui/styles';

export default makeStyles({
    danger: {
        backgroundColor: '#f44336 !important',
        color: 'white !important',
        border: 'none !important',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        textTransform: 'capitalize !important' as any,
    },
    dangerIcon: {
        backgroundColor: '#f44336 !important',
        color: 'white !important',
        border: 'none !important',
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-assignment
        textTransform: 'capitalize !important' as any,
    },
});
