import type { Session } from '@opentok/client';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import { ScreenShareButton } from 'components/ScreenShareButton';
import { usePublisher } from 'core/openTok';
import type { Nilable } from 'types/helpers';

export type ScreenShareProps = {
    currentSession: Nilable<Session>;
    sharingContainer: Nilable<HTMLDivElement>;
    incomingSharing: boolean;
    handleShareClick: (value: boolean) => void;
};

export function ScreenShare({ currentSession, sharingContainer, incomingSharing, handleShareClick }: ScreenShareProps) {
    const { publisher: screenPublisher, publish, unpublish, pubInitialised } = usePublisher();
    const [sharing, setSharing] = useState(false);
    const session = useRef<Nilable<Session>>(null);
    const { showBoundary } = useErrorBoundary();

    const toggleShareScreenClick = useCallback(() => {
        if (session.current && sharingContainer && !sharing) {
            publish({
                session: session.current,
                containerId: sharingContainer.id,
                publisherOptions: { videoSource: 'screen' },
            })
                ?.then(() => {
                    setSharing(true);
                    handleShareClick(true);
                })
                .catch((err: Error) => {
                    if (err.name === 'OT_USER_MEDIA_ACCESS_DENIED') {
                        // L'utilisateur a annulé le partage d'écran
                        setSharing(false);
                        handleShareClick(false);
                    } else {
                        showBoundary({ code: 500, message: 'unable to set audio sources', originalError: err });
                    }
                });
        } else if (session.current && sharing) {
            unpublish({ session: session.current });

            setSharing(false);
            handleShareClick(false);
        }
    }, [handleShareClick, publish, sharing, sharingContainer, showBoundary, unpublish]);

    const streamCreatedListener = useCallback(() => setSharing(true), []);
    const streamDestroyedListener = useCallback(() => {
        setSharing(false);
        handleShareClick(false);
    }, []);

    useEffect(() => {
        if (screenPublisher) screenPublisher.on('streamCreated', streamCreatedListener);
        if (screenPublisher) screenPublisher.on('streamDestroyed', streamDestroyedListener);

        return function cleanup() {
            if (screenPublisher) screenPublisher.off('streamCreated', streamCreatedListener);
            if (screenPublisher) screenPublisher.off('streamDestroyed', streamDestroyedListener);
        };
    }, [screenPublisher, streamCreatedListener, streamDestroyedListener]);

    useEffect(() => {
        if (!pubInitialised && sharing) {
            setSharing(false);
        }
    }, [pubInitialised, sharing]);

    useEffect(() => {
        if (!session.current) {
            session.current = currentSession;
        }
        return () => {
            session.current = null;
        };
    }, [currentSession]);

    return (
        <ScreenShareButton
            disabled={incomingSharing}
            isScreensharing={sharing}
            onClick={toggleShareScreenClick}
        />
    );
}
