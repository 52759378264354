import { Typography } from '@mui/material';

import { ErrorPageWrapper } from 'components/ErrorPageWrapper';

export function SessionEnded() {
    return (
        <ErrorPageWrapper>
            <Typography
                variant="h5"
                component="h2"
            >
                Session terminée
            </Typography>
            <p>La visioconférence est terminée.</p>
            <p>Merci d'avoir participé.</p>
        </ErrorPageWrapper>
    );
}
