import { makeStyles } from '@mui/styles';

export default makeStyles(() => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100vh',
        padding: '5px 15px',
        overflow: 'hidden',
    },
    messageList: {
        display: 'flex',
        flexDirection: 'column',
        flex: '1 1 80%',
        overflow: 'auto',
        color: '#fff',
        width: '260px',
    },
    listItem: {
        widh: '100%',
    },
    selfItem: {
        flexFlow: 'row-reverse',
    },
    chatTitle: {
        position: 'fixed',
        top: 0,
    },
    chatInput: {
        display: 'flex',
        flexDirection: 'row',
    },
}));
