import OT from '@opentok/client';
import NetworkTest from 'opentok-network-test-js';
import type { ConnectivityTestResults } from 'opentok-network-test-js/dist/NetworkTest/testConnectivity';
import type { QualityTestResults } from 'opentok-network-test-js/dist/NetworkTest/testQuality';
import type { OT as OpenTOK } from 'opentok-network-test-js/dist/NetworkTest/types/opentok/index';
import { useCallback, useRef, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import { useAuth } from 'core/auth';

export type NetworkTestState<Data> = {
    data: Data | null;
    loading: boolean;
};

export const useNetworkTest = () => {
    const otNetworkTest = useRef<NetworkTest>();
    const { token } = useAuth();
    const { showBoundary } = useErrorBoundary();
    const [connectivityTest, setConnectivityTest] = useState<NetworkTestState<ConnectivityTestResults>>({
        data: null,
        loading: false,
    });
    const [qualityTest, setQualityTest] = useState<NetworkTestState<QualityTestResults>>({
        data: null,
        loading: false,
    });

    const networkTestVideoTimeout = 5000; // To Edit to 30s

    const initNetworkTest = useCallback(() => {
        if (!otNetworkTest.current) {
            otNetworkTest.current = new NetworkTest(
                OT as unknown as OpenTOK.Client,
                {
                    apiKey: token.apiKey,
                    sessionId: token.sessionId,
                    token: token.token,
                },
                { timeout: networkTestVideoTimeout }
            );
        }
    }, [token]);

    const runNetworkTest = useCallback(async () => {
        initNetworkTest();

        if (otNetworkTest.current && !connectivityTest.loading && !qualityTest.loading) {
            setConnectivityTest((state) => ({ data: state.data, loading: true }));
            setQualityTest((state) => ({ data: state.data, loading: true }));

            try {
                const connectivityResult = await otNetworkTest.current.testConnectivity();

                if (otNetworkTest.current) {
                    setConnectivityTest({ loading: false, data: connectivityResult });

                    const qualityResult = await otNetworkTest.current.testQuality();
                    setQualityTest(() => ({
                        data: qualityResult,
                        loading: false,
                    }));

                    return qualityResult;
                }
            } catch (err) {
                setConnectivityTest((state) => ({ ...state, loading: false }));
                setQualityTest((state) => ({ ...state, loading: false }));

                if (err instanceof Error && err.toString().includes('invalid token')) {
                    showBoundary({ code: 401, message: 'invalid token', originalError: err });
                } else {
                    showBoundary({ code: 500, originalError: err });
                }
            }
        }
    }, [connectivityTest.loading, showBoundary, initNetworkTest, qualityTest.loading]);

    /**
     * Stop the network testConnectivity test. Pleae check limitation
     * https://github.com/opentok/opentok-network-test-js#otnetworkteststop
     */
    const stopNetworkTest = useCallback(() => {
        if (otNetworkTest.current) {
            otNetworkTest.current.stop();
            setConnectivityTest(() => ({ data: null, loading: false }));
            setQualityTest(() => ({ data: null, loading: false }));
        }
    }, []);

    return {
        connectivityTest,
        qualityTest,
        runNetworkTest,
        stopNetworkTest,
        initNetworkTest,
    };
};
