import useStyles from './styles';

export type ChatMessageProps = {
    sender: string;
    date: string;
    text: string;
    self: unknown;
};

export function ChatMessage({ date, sender, text, self }: ChatMessageProps) {
    const classes = useStyles();

    return (
        <div className={`${classes.messageContainer} ${self ? 'self' : ''}`}>
            <p className={classes.sender}>
                {date} - {sender}
            </p>
            <p className={classes.body}>{text}</p>
        </div>
    );
}
