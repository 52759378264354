import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { IconButton, List, ListItem, Typography } from '@mui/material';
import Drawer from '@mui/material/Drawer';
import cn from 'classnames';
import type { MouseEventHandler } from 'react';
import { useEffect, useRef } from 'react';

import { useAuth } from 'core/auth';
import type { Message } from 'core/openTok';
import { ChatInput } from './components/ChatInputs';
import { ChatMessage } from './components/ChatMessage';
import useStyles from './styles';

const anchor = 'right'; // https://material-ui.com/api/drawer/

export type ChatProps = {
    open?: boolean;
    handleToggleChat: MouseEventHandler;
    messages: Message[];
    sendChatMessage: (message: string) => void;
};

export function Chat({ open, handleToggleChat, messages, sendChatMessage }: ChatProps) {
    const classes = useStyles();
    const { user } = useAuth();
    const listRef = useRef<HTMLUListElement>(null);

    const scrollToBottom = () => {
        listRef.current?.scrollTo({ top: listRef.current.scrollHeight, behavior: 'auto' });
    };

    useEffect(scrollToBottom, [messages]);
    useEffect(() => {
        let timeout: number;

        if (open) {
            timeout = window.setTimeout(scrollToBottom);
        }

        return () => clearTimeout(timeout);
    }, [open]);

    return (
        <Drawer
            anchor={anchor}
            open={open}
            onClose={handleToggleChat}
            SlideProps={{
                style: {
                    paddingTop: '40px',
                },
            }}
            ModalProps={{
                container: document.getElementById('visio-rofim'),
                style: {
                    position: 'relative',
                    top: 'unset',
                    left: 'unset',
                    right: 'unset',
                },
            }}
            variant="temporary"
        >
            <div className={classes.container}>
                <Typography
                    variant="h6"
                    component="h4"
                    sx={{ fontSize: '22px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}
                >
                    Chat
                    <IconButton
                        size="small"
                        onClick={handleToggleChat}
                        sx={{ ml: 'auto' }}
                    >
                        <ChevronLeftIcon sx={{ fontSize: '24px' }} />
                    </IconButton>
                </Typography>
                <List
                    className={classes.messageList}
                    ref={listRef}
                >
                    {messages.map((msg) => (
                        <ListItem
                            key={msg.id}
                            className={cn(classes.listItem, {
                                [classes.selfItem]: msg.senderId === user.id,
                            })}
                        >
                            <ChatMessage
                                sender={msg.sender}
                                date={msg.date}
                                text={msg.text}
                                self={msg.senderId === user.id}
                            />
                        </ListItem>
                    ))}
                </List>
                <ChatInput
                    className={classes.chatInput}
                    sendMessage={sendChatMessage}
                ></ChatInput>
            </div>
        </Drawer>
    );
}
