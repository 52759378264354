import type { ReactNode } from 'react';

import rofimLogo from 'assets/rofimLogo.png';
import useStyles from './styles';

export type ErrorPageWrapperProps = {
    children: ReactNode;
};

export function ErrorPageWrapper({ children }: ErrorPageWrapperProps) {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.container2}>
                <div className={classes.content}>
                    <img
                        src={rofimLogo}
                        alt="Rofim logo"
                        className={classes.logo}
                    />
                    {children}
                </div>
            </div>
        </div>
    );
}
