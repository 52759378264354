import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import PhoneIcon from '@mui/icons-material/Phone';
import type { Session, Stream, SubscriberProperties } from '@opentok/client';
import OT from '@opentok/client';
import _ from 'lodash';
import type { LayoutContainer } from 'opentok-layout-js';
import initLayoutContainer from 'opentok-layout-js';
import type { RefObject } from 'react';
import { useCallback, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import { useErrorBoundary } from 'react-error-boundary';

import sound from 'assets/sound.gif';
import type { Token } from 'core/auth';
import { useAuth } from 'core/auth';
import type { Participant, SpeakerPosition } from 'core/openTok';
import { useOtSpeech } from 'core/openTok';
import { capitalize, render, useAvatar } from 'helpers';
import type { Nilable } from 'types/helpers';
import type { OtPublisher, OtStream, OtSubscriber, WithSip } from 'types/openTok';

const speakingThreshold = 500;
const notSpeakingThreshold = 2000;
const initialNumberOfActiveSpeakers = 10;
const voiceLevelThreshold = 0.001;
const audioStream = {
    isTalking: false,
    timestamp: 0,
};

export type UseSessionOptions = {
    container: RefObject<HTMLDivElement>;
    sharingContainer: RefObject<HTMLDivElement>;
};

export type HandleStreamOptions = {
    stream: OtStream;
};

export type MediaStreamEvent = { target: { stream: OtStream }; audioLevel: number };

export function useSession({ container, sharingContainer }: UseSessionOptions) {
    const [connected, setConnected] = useState(false);
    const [connecting, setConnecting] = useState(false);
    const [incomingSharing, setIncomingSharing] = useState(false);
    const [streams, setStreams] = useState<OtStream[]>([]);

    const sessionRef = useRef<Session | null>(null);
    const otLayoutRef = useRef<LayoutContainer['layout'] | null>(null);
    const publisherRef = useRef<OtPublisher | null>(null);

    const { user } = useAuth();
    const otSpeech = useOtSpeech();
    const { getAvatarUrl } = useAvatar();
    const { showBoundary } = useErrorBoundary();

    const addStream = useCallback(
        ({ stream }: HandleStreamOptions) => {
            if (streams.find((s) => s.id === stream.id)) return;
            setStreams((prev) => [...prev, stream]);
        },
        [streams]
    );

    const removeStream = ({ stream }: HandleStreamOptions) => {
        setStreams((prev) => prev.filter((prevStream) => prevStream.id !== stream.id));
    };

    const updateActiveSpeakerEl = (id: string, action: string) => {
        const el = id ? document.getElementById(id) : null;
        if (el) {
            if (action === 'add') {
                el.classList.add('active-speaker-border');
            } else {
                el.classList.remove('active-speaker-border');
            }
        }
    };

    const calculateAudioLevel = useCallback((event: MediaStreamEvent) => {
        const streamId = event.target?.stream?.id;
        if (streamId && event.target?.stream?.hasAudio) {
            let movingAvg: Nilable<number> = null;
            if (movingAvg === null || movingAvg <= event.audioLevel) {
                movingAvg = event.audioLevel;
            } else {
                movingAvg = 0.8 * movingAvg + 0.2 * event.audioLevel;
            }
            // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
            const currentLogLevel = Math.log(movingAvg) / Math.LN10 / 1.5 + 1;
            const speaking = Math.min(Math.max(currentLogLevel, 0), 1);
            const streamElements = document.querySelector(`[data-stream-id="${streamId}`);
            if (streamElements) {
                const audioLevelElement = streamElements.querySelector('.audioLevelIndicator');
                if (speaking) {
                    audioLevelElement?.classList.remove('off');
                    audioLevelElement?.classList.add('on');
                } else {
                    audioLevelElement?.classList.remove('on');
                    audioLevelElement?.classList.add('off');
                }
            }
            const participantElements = document.querySelector(`[data-stream-id="p-${streamId}"]`);
            if (participantElements) {
                if (speaking) {
                    participantElements.classList.remove('off');
                    participantElements.classList.add('on');
                } else {
                    participantElements.classList.remove('on');
                    participantElements.classList.add('off');
                }
            }
        }
    }, []);

    const onAudioLevel = useCallback(
        (event: MediaStreamEvent, elementId: string) => {
            calculateAudioLevel(event);
            const now = new Date().getTime();
            if (event && event.audioLevel > voiceLevelThreshold) {
                // it could be speaking
                if (!audioStream.isTalking) {
                    audioStream.isTalking = true;
                    audioStream.timestamp = new Date().getTime();
                } else if (audioStream.isTalking && now - audioStream.timestamp > speakingThreshold) {
                    audioStream.isTalking = true;
                    audioStream.timestamp = new Date().getTime();
                    // this means that it's speaking for more than X seconds
                    updateActiveSpeakerEl(elementId, 'add');
                }
            } else if (audioStream.isTalking && now - audioStream.timestamp > notSpeakingThreshold) {
                // low audio detected for X seconds
                audioStream.isTalking = false;
                updateActiveSpeakerEl(elementId, 'remove');
                otLayoutRef.current && otLayoutRef.current();
            }
        },
        [calculateAudioLevel]
    );

    const subscribe = useCallback(
        (stream: OtStream, options = {}) => {
            const appendContainer = stream.videoType === 'screen' ? sharingContainer.current : container.current;
            if (sessionRef.current && appendContainer) {
                const finalOptions: SubscriberProperties = {
                    ...options,
                    insertMode: 'append',
                    width: '100%',
                    height: '100%',
                    style: {
                        buttonDisplayMode: 'auto',
                        nameDisplayMode: 'auto',
                        audioLevelDisplayMode: 'auto',
                    },
                    showControls: true,
                    subscribeToAudio: true,
                    subscribeToVideo: true,
                };

                const subscriber = sessionRef.current.subscribe(
                    stream,
                    appendContainer.id,
                    finalOptions
                ) as OtSubscriber;

                subscriber.element?.setAttribute('data-stream-id', subscriber.stream.id);

                if (stream.videoType !== 'screen') {
                    // Add Subscriber to OT Speech
                    otSpeech.addSubscriber(subscriber);
                } else {
                    sharingContainer.current?.classList.add('visible');
                    sharingContainer.current?.classList.remove('hidden');
                    setIncomingSharing(true);
                    publisherRef.current?.publishVideo(false);
                }

                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                const handleAudioLevelUpdated = _.throttle(
                    (event: MediaStreamEvent) => onAudioLevel(event, subscriber.id),
                    50
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                ) as any;

                // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
                subscriber.on('audioLevelUpdated', handleAudioLevelUpdated);

                subscriber.on('videoElementCreated', (event) => {
                    event.target.subscribeToVideo(true);

                    const participant: Participant | null = subscriber.stream?.name
                        ? (JSON.parse(subscriber.stream?.name) as Participant)
                        : null;
                    const streamElements = document.querySelector(`[data-stream-id="${subscriber.stream?.id}`);
                    if (streamElements && subscriber.stream?.videoType !== 'screen') {
                        // Set up the audio level indicator
                        const tempDiv = document.createElement('div');
                        tempDiv.className = 'audioLevelIndicator';

                        const soundImage = document.createElement('img');
                        soundImage.alt = 'soundAnimated';
                        soundImage.src = sound;

                        tempDiv.appendChild(soundImage);
                        streamElements.append(tempDiv);

                        const otNameElement = streamElements.querySelector('.OT_name') as HTMLElement;
                        const otVideoPosterElement = streamElements.querySelector('.OT_video-poster') as HTMLElement;
                        const otAudioLevelElement = streamElements.querySelector('.OT_audio-level-meter');

                        if (participant) {
                            const src = `${getAvatarUrl(participant.firstname, participant.lastname, true)}`;
                            createRoot(otVideoPosterElement).render(
                                <img
                                    alt="avatar"
                                    src={src}
                                />
                            );
                            const firstName = capitalize(participant.firstname);
                            const lastName = capitalize(participant.lastname);
                            otNameElement.innerHTML = `${firstName} ${lastName}`;
                        } else {
                            otNameElement.innerHTML = 'Connexions par tél�phone';
                            render(<PhoneIcon preserveAspectRatio="xMidYMid meet" />, otVideoPosterElement);
                        }

                        otNameElement.style.display = 'block';

                        if (subscriber.stream?.hasAudio) {
                            render(<MicIcon />, otAudioLevelElement);
                        } else {
                            render(<MicOffIcon />, otAudioLevelElement);
                        }
                    }
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [container, onAudioLevel, otSpeech, sharingContainer]
    );

    // OT event are hard to properly type ...so any ...
    // TODO better event type
    const onStreamCreated = useCallback(
        (event: { stream: OtStream }) => {
            if (!event.stream.connection?.data) {
                return showBoundary({});
            }
            //avoid streams created in waiting rooms
            let isSip = false;
            try {
                const streamConnection = JSON.parse(event.stream.connection.data) as WithSip;
                isSip = !!streamConnection?.sip;
                // eslint-disable-next-line no-empty
            } catch (err: unknown) {}

            if (event.stream.videoType !== 'screen' && !isSip && !event.stream?.name) return;

            subscribe(event.stream);
            addStream({ stream: event.stream });
            otLayoutRef.current && otLayoutRef.current();
        },
        [addStream, showBoundary, subscribe]
    );

    // OT event are hard to properly type ...so any ...
    // TODO better event type
    const onStreamDestroyed = useCallback(
        (event: { stream: OtStream }) => {
            if (event.stream?.videoType === 'screen') {
                setIncomingSharing(false);
                sharingContainer.current?.classList.add('hidden');
                sharingContainer.current?.classList.remove('visible');
                publisherRef.current?.publishVideo(user.defaultSettings.publishVideo);
            } else {
                // Remove Subscriber from OT Speech
                otSpeech.removeSubscriberByStreamId(event.stream?.id);
            }

            removeStream({ stream: event.stream });

            const timeout = window.setTimeout(() => otLayoutRef.current && otLayoutRef.current(), 200);

            return () => clearTimeout(timeout);
        },
        [otSpeech, sharingContainer, user.defaultSettings.publishVideo]
    );

    // This callback/listener will handle the change of speaker order
    // based on the speech detection via audio level
    const onSpeakerOrderChanged = useCallback(
        (_: unknown, positions: SpeakerPosition[]) => {
            if (!incomingSharing && publisherRef.current) {
                const mappedPositions = positions.filter((subscriberId) => subscriberId != null);

                const shouldPublisherBeVisible = mappedPositions.includes(publisherRef.current?.streamId);

                const streamElement = document.querySelectorAll(
                    `[data-stream-id="${publisherRef.current.streamId}`
                )[0] as HTMLElement;

                if (streamElement) {
                    streamElement.style.display = shouldPublisherBeVisible ? 'block' : 'none';
                    publisherRef.current?.publishVideo(shouldPublisherBeVisible && user.defaultSettings.publishVideo);
                }

                streams.forEach((stream) => {
                    const streamElements = document.querySelectorAll(`[data-stream-id="${stream.id}"]`);

                    if (streamElements[0] && streamElements[0] instanceof HTMLElement)
                        streamElements[0].style.display = mappedPositions.includes(stream.id) ? 'block' : 'none';
                });

                otLayoutRef.current && otLayoutRef.current();
            }
        },
        [incomingSharing, streams, user.defaultSettings.publishVideo]
    );

    const onStreamPropertyChanged = useCallback(
        ({
            changedProperty,
            newValue,
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            oldValue,
            stream,
        }: {
            changedProperty: string;
            newValue: unknown;
            oldValue: unknown;
            stream: OtStream;
        }) => {
            const streamElements = document.querySelector(`[data-stream-id="${stream.id}"]`);
            let timeout: number;

            if (streamElements) {
                if (changedProperty === 'hasAudio') {
                    const otAudioMeterElement = streamElements.querySelector('.OT_audio-level-meter');
                    if (newValue) {
                        render(<MicIcon />, otAudioMeterElement);
                    } else {
                        render(<MicOffIcon />, otAudioMeterElement);

                        const audioLevelElement = streamElements.querySelector('.audioLevelIndicator');

                        if (audioLevelElement) {
                            timeout = window.setTimeout(() => {
                                audioLevelElement.classList.remove('on');
                                audioLevelElement.classList.add('off');
                            }, 100);
                        }
                    }
                }
            }

            return () => clearTimeout(timeout);
        },
        []
    );

    const createSession = useCallback(
        ({ apiKey, sessionId, token }: Pick<Token, 'apiKey' | 'sessionId' | 'token'>) => {
            if (!apiKey) {
                showBoundary({ code: 401, message: 'Missing apiKey' });
            }

            if (!sessionId) {
                showBoundary({ code: 401, message: 'Missing sessionId' });
            }

            if (!token) {
                showBoundary({ code: 401, message: 'Missing token' });
            }

            const eventHandlers = {
                streamCreated: onStreamCreated,
                streamDestroyed: onStreamDestroyed,
                streamPropertyChanged: onStreamPropertyChanged,
            };

            if (!connected && !connecting) {
                setConnecting(true);
                sessionRef.current = OT.initSession(apiKey, sessionId);
                otSpeech.addSelf();
                sessionRef.current.on(eventHandlers);
            }

            otSpeech.setNumberOfActiveSpeakers(initialNumberOfActiveSpeakers);
            otSpeech.setVoiceLevelThreshold(voiceLevelThreshold);

            // TODO fix any typing
            // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
            otLayoutRef.current = initLayoutContainer(container.current as any).layout;

            if (!sessionRef.current) {
                return;
            }

            return new Promise((resolve, reject) => {
                if (connected) {
                    resolve(sessionRef.current);
                    return;
                }

                sessionRef.current?.connect(token, (err) => {
                    if (err) {
                        showBoundary({ code: 401, originalError: err });
                        reject(err);
                    } else if (!err) {
                        setConnected(true);
                        resolve(sessionRef.current);
                    }
                });
            });
        },
        [
            onStreamCreated,
            onStreamDestroyed,
            onStreamPropertyChanged,
            connected,
            connecting,
            otSpeech,
            container,
            showBoundary,
        ]
    );

    const destroySession = useCallback(() => {
        if (sessionRef.current) {
            sessionRef.current.on('disconnected', () => {
                sessionRef.current = null;
            });
            sessionRef.current.disconnect();
            setConnected(false);
            otSpeech.removeSelf();
        }
    }, [otSpeech]);

    const refreshLayout = useCallback(() => {
        otLayoutRef.current && otLayoutRef.current();
    }, []);

    const setOnActiveSpeakerChangeListener = useCallback(
        (publisher: OtPublisher) => {
            if (streams && streams.length) {
                publisherRef.current = publisher;
                otSpeech.setOnActiveSpeakerChangeListener(onSpeakerOrderChanged);
            }
        },
        [onSpeakerOrderChanged, otSpeech, streams]
    );

    const muteParticipant = useCallback(
        (participant: Participant) => {
            const stream = streams.find(({ id }) => id === participant.id);

            if (stream) {
                return sessionRef.current?.forceMuteStream(stream);
            }
        },
        [streams, sessionRef]
    );

    const muteAll = useCallback(() => {
        return sessionRef.current?.forceMuteAll([publisherRef.current?.stream as Stream]);
    }, [sessionRef]);

    return {
        session: sessionRef,
        streams,
        connected,
        incomingSharing,
        createSession,
        destroySession,
        refreshLayout,
        muteParticipant,
        muteAll,
        setOnActiveSpeakerChangeListener,
    };
}
