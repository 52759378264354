import type { SelectChangeEvent } from '@mui/material';
import { Button, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import 'moment/locale/fr';
import type { QualityTestResults } from 'opentok-network-test-js/dist/NetworkTest/testQuality';
import type { ChangeEvent } from 'react';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';

import rofimLogo from 'assets/rofimLogo.png';
import AudioSettings from 'components/AudioSetting';
import QualityTestErrorDialog from 'components/QualityTestErrorDialog';
import QualityTestProgressDialog from 'components/QualityTestProgressDialog';
import { Spinner } from 'components/Spinner';
import { VideoSettings } from 'components/VideoSetting';
import type { User } from 'core/auth';
import { useAuth } from 'core/auth';
import { getAudioSourceDeviceId, useNetworkTest, usePublisher } from 'core/openTok';
import { logger } from 'helpers';
import type { Optional } from 'types/helpers';
import useStyles from './styles';

/**
 * Entry point of rofim-visio
 * The user can parameters his audio / video stream before joining the call
 */
export function WaitingRoom() {
    const classes = useStyles();
    const { user, setUser, token } = useAuth();
    const navigate = useNavigate();
    const { showBoundary } = useErrorBoundary();

    const [joinIsRequested, setJoinIsRequested] = useState(false);
    const [qualityTestData, setQualityTestData] = useState<QualityTestResults | null>(null);

    const [localAudio, setLocalAudio] = useState(true);
    const [localVideo, setLocalVideo] = useState(true);
    const [localVideoSource, setLocalVideoSource] = useState<string | undefined>();
    const [localAudioSource, setLocalAudioSource] = useState<string | undefined>();
    const [audioDevice, setAudioDevice] = useState<Optional<string>>('');
    const [videoDevice, setVideoDevice] = useState<Optional<string>>('');
    const [localAudioOutput, setAudioOutputDevice] = useState('');
    const [showQualityErrorDialog, setShowQualityErrorDialog] = useState(false);
    const [showQualityTestProgressDialog, setShowQualityTestProgressDialog] = useState(false);

    const waitingRoomVideoContainer = useRef<HTMLDivElement | null>(null);

    const { publisher, initPublisher, destroyPublisher, deviceInfo, logLevel, pubInitialised, setAudioIcon } =
        usePublisher();

    const handleAudioChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setLocalAudio(e.target.checked);
    }, []);

    const handleVideoChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
        setLocalVideo(e.target.checked);
    }, []);

    const handleVideoSource = useCallback(
        (e: SelectChangeEvent<string>) => {
            if (!publisher) {
                throw new Error('no publisher');
            }

            const videoDeviceId = e.target.value;
            setVideoDevice(e.target.value);
            publisher
                .setVideoSource(videoDeviceId)
                .then(() => logger.debug('set video source'))
                .catch((err: unknown) => {
                    showBoundary({ code: 500, message: 'video source error', originalError: err });
                });
            setLocalVideoSource(videoDeviceId);
        },
        [publisher, showBoundary]
    );

    const handleAudioSource = useCallback(
        (e: SelectChangeEvent<string>) => {
            if (!publisher) {
                throw new Error('no publisher');
            }
            const audioDeviceId = e.target.value;
            setAudioDevice(audioDeviceId);
            publisher
                .setAudioSource(audioDeviceId)
                .then((source) => logger.debug('set audio source:', source))
                .catch((err: unknown) => {
                    showBoundary({ code: 500, message: 'video source error', originalError: err });
                });
            setLocalAudioSource(audioDeviceId);
        },
        [publisher, showBoundary]
    );

    const handleQualityTestDialogClose = () => {
        stopNetworkTest();
        setShowQualityErrorDialog(false);
    };

    const { connectivityTest, qualityTest, runNetworkTest, stopNetworkTest } = useNetworkTest();

    const handleJoinClick = useCallback(() => {
        if (!qualityTestData && !qualityTest.data) {
            runNetworkTest()
                .then((data) => logger.debug('network test:', data))
                .catch((err: unknown) => {
                    showBoundary({ code: 500, message: 'network test error', originalError: err });
                });
        }
        setShowQualityTestProgressDialog(true);
        setJoinIsRequested(true);
    }, [qualityTest.data, qualityTestData, runNetworkTest, showBoundary]);

    useEffect(() => {
        if (joinIsRequested && qualityTestData && !qualityTestData.audio.reason && token.room) {
            setJoinIsRequested(false);
            setQualityTestData(null);
            stopNetworkTest();
            navigate(`room/${token.room}`);
        }
    }, [joinIsRequested, navigate, qualityTestData, stopNetworkTest, token.room]);

    useEffect(() => {
        if (waitingRoomVideoContainer.current && user.userName && !pubInitialised && !publisher) {
            const publisherOptions = {
                publishAudio: localAudio,
                publishVideo: localVideo,
                name: JSON.stringify({ firstname: user.firstname, lastname: user.lastname }),
            };
            initPublisher(waitingRoomVideoContainer.current.id, publisherOptions);
        }
    }, [
        initPublisher,
        localAudio,
        localVideo,
        user.userName,
        user.firstname,
        user.lastname,
        pubInitialised,
        publisher,
        runNetworkTest,
    ]);

    useEffect(() => {
        if (!qualityTestData && !qualityTest.data && !connectivityTest.loading && !qualityTest.loading) {
            runNetworkTest()
                .then((data) => logger.debug('network test:', data))
                .catch((err: unknown) => {
                    showBoundary({ code: 500, message: 'network test error', originalError: err });
                });
        }
    }, [
        connectivityTest.loading,
        qualityTest.data,
        qualityTest.loading,
        qualityTestData,
        runNetworkTest,
        showBoundary,
    ]);

    useEffect(() => {
        if (publisher) {
            publisher.publishAudio(localAudio);
            setAudioIcon(localAudio);
        }
    }, [localAudio, publisher, setAudioIcon]);

    useEffect(() => {
        if (publisher) {
            publisher.publishVideo(localVideo);
        }
    }, [localVideo, publisher]);

    useEffect(() => {
        if (
            joinIsRequested &&
            qualityTestData &&
            (!connectivityTest.data?.success || !qualityTestData.audio.supported)
        ) {
            setJoinIsRequested(false);
            setShowQualityErrorDialog(true);
        }
    }, [connectivityTest.data?.success, joinIsRequested, qualityTestData]);

    useEffect(() => {
        if (publisher && pubInitialised && deviceInfo) {
            const currentAudioDevice = publisher.getAudioSource();
            setAudioDevice(getAudioSourceDeviceId(deviceInfo.audioInputDevices, currentAudioDevice));
            const currentVideoDevice = publisher.getVideoSource();
            setVideoDevice(currentVideoDevice.deviceId as string);

            OT.getActiveAudioOutputDevice()
                .then((currentAudioOutputDevice) => {
                    logger.debug('Active audio output:', currentAudioOutputDevice);
                    setAudioOutputDevice(currentAudioOutputDevice.deviceId as string);
                })
                .catch((err: unknown) => {
                    showBoundary({ code: 500, message: 'no active audio output', originalError: err });
                    logger.warn('OT.getActiveAudioOutputDevice() rejected 1', err);
                });
        }
    }, [deviceInfo, publisher, setAudioDevice, setVideoDevice, pubInitialised, showBoundary]);

    useEffect(() => {
        return () => {
            destroyPublisher();
        };
    }, [destroyPublisher]);

    useEffect(() => {
        if (qualityTest.data) {
            setQualityTestData(qualityTest.data);
        }
    }, [qualityTest.data, qualityTest.loading]);

    useEffect(() => {
        setUser({
            defaultSettings: {
                publishAudio: localAudio,
                publishVideo: localVideo,
                frameRate: qualityTestData?.video.recommendedFrameRate,
                resolution: qualityTestData?.video.recommendedResolution,
                audioSource: localAudioSource,
                videoSource: localVideoSource,
                audioOutput: localAudioOutput,
            },
            userName: user.userName,
            qualityTestData,
        } as User);
    }, [
        localAudio,
        localVideo,
        setUser,
        localAudioSource,
        localVideoSource,
        localAudioOutput,
        user.userName,
        qualityTestData?.video.recommendedFrameRate,
        qualityTestData?.video.recommendedResolution,
        qualityTestData,
    ]);

    return (
        <>
            <div className={classes.waitingRoomContainer}>
                <div className={classes.leftSide}>
                    <img
                        src={rofimLogo}
                        alt="Rofim logo"
                        className={classes.logo}
                    />
                    <div className={classes.subTitle}>{token?.title}</div>
                    <div className={classes.subTitle}>
                        {token?.type?.toUpperCase()} du{' '}
                        <Moment
                            format="L"
                            date={token?.startDate}
                        />{' '}
                        à{' '}
                        <Moment
                            format="LT"
                            date={token?.startDate}
                        />
                    </div>
                    <div className={classes.boldTitle}>Prêt à participer ?</div>
                    <div className={classes.subText}>Vous êtes sur le point de rejoindre une vidéo conférence.</div>
                    <div className={classes.subText}>
                        Vous pouvez paramétrer votre micro et caméra avant de rejoindre l’appel.
                    </div>
                    <QualityTestProgressDialog open={showQualityTestProgressDialog} />
                    <QualityTestErrorDialog
                        open={showQualityErrorDialog}
                        onClose={handleQualityTestDialogClose}
                    />
                </div>
                <div className={classes.rightSide}>
                    <Grid>
                        <div
                            id="waiting-room-video-container"
                            className={classes.waitingRoomVideoPreview}
                            ref={waitingRoomVideoContainer}
                        ></div>
                        <form
                            className={classes.form}
                            noValidate
                        >
                            <div className={classes.deviceContainer}>
                                <VideoSettings
                                    className={classes.deviceSettings}
                                    hasVideo={localVideo}
                                    onVideoChange={handleVideoChange}
                                />
                                {deviceInfo && localVideo && (
                                    <div className={classes.mediaSources}>
                                        <FormControl>
                                            <InputLabel id="video">Source Video</InputLabel>
                                            {deviceInfo.videoInputDevices && (
                                                <Select
                                                    variant="standard"
                                                    labelId="video"
                                                    id="video"
                                                    className={classes.select}
                                                    value={videoDevice}
                                                    onChange={handleVideoSource}
                                                >
                                                    {deviceInfo.videoInputDevices.map((device) => (
                                                        <MenuItem
                                                            key={device.deviceId}
                                                            value={device.deviceId}
                                                        >
                                                            {device.label}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            )}
                                        </FormControl>
                                    </div>
                                )}
                                <AudioSettings
                                    className={classes.deviceSettings}
                                    hasAudio={localAudio}
                                    onAudioChange={handleAudioChange}
                                    logLevel={logLevel}
                                />
                                {deviceInfo && localAudio && (
                                    <div className={classes.mediaSources}>
                                        <FormControl>
                                            <InputLabel id="audio">Source Audio</InputLabel>
                                            <Select
                                                variant="standard"
                                                labelId="audio"
                                                id="audio"
                                                className={classes.select}
                                                value={audioDevice}
                                                onChange={handleAudioSource}
                                            >
                                                {deviceInfo.audioInputDevices.map((device) => (
                                                    <MenuItem
                                                        key={device.deviceId}
                                                        value={device.deviceId}
                                                    >
                                                        {device.label}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </div>
                                )}
                            </div>
                            <Button
                                sx={{ display: 'flex', margin: 'auto', fontSize: '14px' }}
                                variant="contained"
                                color="primary"
                                disabled={
                                    !!qualityTestData &&
                                    (!connectivityTest.data?.success || !qualityTestData.audio.supported)
                                }
                                onClick={handleJoinClick}
                            >
                                Participer
                                {joinIsRequested && (
                                    <CircularProgress
                                        size={20}
                                        sx={{ margin: '0 0 0 10px', color: 'common.white' }}
                                    />
                                )}
                            </Button>
                        </form>
                    </Grid>
                </div>
            </div>
            {joinIsRequested && (
                <div className={`${classes.loader} loader-container`}>
                    <Spinner />
                </div>
            )}
        </>
    );
}
