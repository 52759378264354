import Mic from '@mui/icons-material/Mic';
import { LinearProgress, Switch } from '@mui/material';
import type { ChangeEventHandler } from 'react';

import useStyles from './styles';

export type AudioSettingsProps = {
    hasAudio: boolean;
    onAudioChange: ChangeEventHandler;
    className?: string;
    logLevel: number;
};

export default function AudioSettings({ hasAudio, onAudioChange, className, logLevel }: AudioSettingsProps) {
    const classes = useStyles();

    return (
        <div className={className}>
            <div className={classes.circle}>
                <Mic style={{ fontSize: 24 }} />
            </div>
            <div className={classes.micContainer}>
                <p className={classes.text}>Microphone</p>
                <div className={classes.micLevel}>
                    <LinearProgress
                        variant="determinate"
                        value={logLevel}
                        style={{ borderRadius: '50px' }}
                    />
                </div>
            </div>
            <Switch
                color="primary"
                checked={hasAudio}
                onChange={onAudioChange}
                name="AudioToggle"
            />
        </div>
    );
}
