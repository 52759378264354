import { makeStyles } from '@mui/styles';

export default makeStyles(
    (theme) => ({
        formControl: {
            marginBottom: theme.spacing(2),
        },
        mediaSources: {
            '& label': {
                fontSize: '16px',
            },
        },
        select: {
            fontSize: '16px !important',
        },
        cameraSwitch: {
            display: 'flex',
            alignItems: 'center',
        },
        switchButton: {
            marginRight: theme.spacing(2),
        },
        flex: {
            display: 'flex',
            flexDirection: 'column',
        },
    }),
    { index: 1 }
);
