import type { AudioOutputDevice, Device } from '@opentok/client';
import OT from '@opentok/client';
import { useCallback, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import { logger } from 'helpers';

export type DeviceState = {
    audioInputDevices: Device[];
    videoInputDevices: Device[];
    audioOutputDevices: AudioOutputDevice[];
};

export function useDevices() {
    const [deviceInfo, setDeviceInfo] = useState<DeviceState>({
        audioInputDevices: [],
        videoInputDevices: [],
        audioOutputDevices: [],
    });
    const { showBoundary } = useErrorBoundary();

    const getDevices = useCallback(() => {
        if (!navigator.mediaDevices || !navigator.mediaDevices.enumerateDevices) {
            logger.warn('enumerateDevices() not supported.');
        }

        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        OT.getDevices(async (err, devices) => {
            if (err) {
                showBoundary({ code: 500, message: 'devices error', originalError: err });
                return;
            }
            try {
                let audioOutputDevices = await OT.getAudioOutputDevices();
                audioOutputDevices = audioOutputDevices.map((audiooutput) =>
                    audiooutput.deviceId === 'default' ? { ...audiooutput, label: 'System Default' } : audiooutput
                );
                const audioInputDevices = devices ? devices.filter((d) => d.kind.toLowerCase() === 'audioinput') : [];
                const videoInputDevices = devices ? devices.filter((d) => d.kind.toLowerCase() === 'videoinput') : [];

                setDeviceInfo({
                    audioInputDevices,
                    videoInputDevices,
                    audioOutputDevices,
                });
            } catch (err) {
                showBoundary({ code: 500, message: 'audio devices error', originalError: err });
            }
        });
    }, [showBoundary]);

    useEffect(() => {
        navigator.mediaDevices.addEventListener('devicechange', getDevices);
        getDevices();

        return () => {
            navigator.mediaDevices.removeEventListener('devicechange', getDevices);
        };
    }, [getDevices]);

    return { deviceInfo, getDevices };
}
