import SettingsIcon from '@mui/icons-material/Settings';
import type { SelectChangeEvent } from '@mui/material';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Typography,
} from '@mui/material';
import OT from '@opentok/client';
import type { MouseEventHandler } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';

import type { DeviceState } from 'core/openTok';
import { getAudioSourceDeviceId } from 'core/openTok';
import { logger } from 'helpers';
import type { Nilable, Optional } from 'types/helpers';
import type { OtPublisher } from 'types/openTok';
import useStyles from './styles';

export type SettingsModalProps = {
    open: boolean;
    onCloseClick: MouseEventHandler;
    currentPublisher: Nilable<OtPublisher>;
    deviceInfo: DeviceState;
};

export function SettingsModal({ open, onCloseClick, currentPublisher, deviceInfo }: SettingsModalProps) {
    const [audioDevice, setAudioDevice] = useState<Optional<string>>();
    const [videoDevice, setVideoDevice] = useState<Optional<string>>();
    const [audioOutputDevice, setAudioOutputDevice] = useState<Optional<string>>();
    const { showBoundary } = useErrorBoundary();
    // const [localVideoSource, setLocalVideoSource] = useState<Nilable<string>>();
    // const [localAudioSource, setLocalAudioSource] = useState<Nilable<string>>();
    // const [localAudioOutput, setLocalAudioOutput] = useState<Nilable<string>>();
    //
    const classes = useStyles();

    /* async function handleAudioInputChange(e) {
    const audioInputs = await fetchAudioInput();
    const [selectedAudioInput] = audioInputs.filter(
      (audioInput) => audioInput.label === e.target.value
    );

    if (selectedAudioInput && currentPublisher) {
      currentPublisher.setAudioSource(selectedAudioInput.deviceId);
      setSelectedAudioInput(selectedAudioInput);
    }
  } */

    // console.log('modal', deviceInfo);

    const handleVideoSource = useCallback(
        (e: SelectChangeEvent<string>) => {
            if (!currentPublisher) {
                throw new Error('missing publisher');
            }
            const videoDeviceId = e.target.value;
            setVideoDevice(e.target.value);
            currentPublisher.setVideoSource(videoDeviceId)?.catch((err: unknown) => {
                showBoundary({ code: 500, message: 'unable to set audio sources', originalError: err });
            });
            // setLocalVideoSource(videoDeviceId);
        },
        [currentPublisher, showBoundary]
    );

    const handleAudioSource = useCallback(
        (e: SelectChangeEvent<string>) => {
            if (!currentPublisher) {
                throw new Error('missing publisher');
            }
            const audioDeviceId = e.target.value;
            setAudioDevice(audioDeviceId);
            currentPublisher.setAudioSource(audioDeviceId)?.catch((err: unknown) => {
                showBoundary({ code: 500, message: 'unable to set audio sources', originalError: err });
            });
            // setLocalAudioSource(audioDeviceId);
        },
        [currentPublisher, showBoundary]
    );

    const handleAudioOutput = useCallback(
        (e: SelectChangeEvent<string>) => {
            const audioOutputId = e.target.value;
            setAudioOutputDevice(audioOutputId);
            OT.setAudioOutputDevice(audioOutputId)?.catch((err: unknown) => {
                showBoundary({ code: 500, message: 'unable to set audio sources', originalError: err });
            });

            // setLocalAudioOutput(audioOutputId);
        },
        [showBoundary]
    );

    useEffect(() => {
        if (currentPublisher && deviceInfo) {
            const currentAudioDevice = currentPublisher.getAudioSource();
            setAudioDevice(getAudioSourceDeviceId(deviceInfo.audioInputDevices, currentAudioDevice));
            const currentVideoDevice = currentPublisher.getVideoSource();
            setVideoDevice(currentVideoDevice.deviceId || undefined);

            OT.getActiveAudioOutputDevice()
                .then((currentAudioOutputDevice) => {
                    setAudioOutputDevice(currentAudioOutputDevice.deviceId || undefined);
                })
                .catch((err) => {
                    logger.warn('OT.getActiveAudioOutputDevice() rejected 1', err);
                });
        }
    }, [deviceInfo, currentPublisher, setAudioDevice, setVideoDevice, setAudioOutputDevice]);

    return (
        <Dialog
            open={open}
            fullWidth
            container={() => document.getElementById('visio-rofim')}
        >
            <DialogTitle>
                <Typography sx={{ fontSize: '22px', display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <SettingsIcon sx={{ fontSize: '22px', mr: '5px' }} />
                    Paramètres
                </Typography>
            </DialogTitle>
            <DialogContent className={classes.flex}>
                <div>Vous pouvez modifier les paramètres pour la caméra et le micro ici.</div>
                <FormControl
                    variant="standard"
                    sx={{ marginTop: '20px' }}
                    className={classes.mediaSources}
                >
                    <InputLabel id="demo-simple-select-label">Choisir la source audio</InputLabel>
                    <Select
                        variant="standard"
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={audioDevice}
                        onChange={handleAudioSource}
                        className={classes.select}
                    >
                        {deviceInfo.audioInputDevices.map((device) => (
                            <MenuItem
                                key={device.deviceId}
                                value={device.deviceId}
                            >
                                {device.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <FormControl
                    variant="standard"
                    sx={{ marginTop: '15px' }}
                    className={classes.mediaSources}
                >
                    <InputLabel id="video">Choisir la sortie audio</InputLabel>
                    {deviceInfo.audioOutputDevices && (
                        <Select
                            variant="standard"
                            labelId="video"
                            id="demo-simple-select"
                            value={audioOutputDevice}
                            onChange={handleAudioOutput}
                            autoWidth={true}
                            className={classes.select}
                        >
                            {deviceInfo.audioOutputDevices.map((device) => (
                                <MenuItem
                                    key={device.deviceId}
                                    value={device.deviceId as string}
                                >
                                    {device.label}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </FormControl>
                <FormControl
                    variant="standard"
                    sx={{ marginTop: '15px' }}
                    className={classes.mediaSources}
                >
                    <InputLabel id="video">Choisir la sourece vidéo</InputLabel>
                    {deviceInfo.videoInputDevices && (
                        <Select
                            variant="standard"
                            labelId="video"
                            id="demo-simple-select"
                            value={videoDevice}
                            onChange={handleVideoSource}
                            className={classes.select}
                        >
                            {deviceInfo.videoInputDevices.map((device) => (
                                <MenuItem
                                    key={device.deviceId}
                                    value={device.deviceId}
                                >
                                    {device.label}
                                </MenuItem>
                            ))}
                        </Select>
                    )}
                </FormControl>
            </DialogContent>
            <DialogActions>
                <Button
                    color="primary"
                    onClick={onCloseClick}
                    sx={{ fontSize: '14px' }}
                >
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    );
}
