import cn from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useErrorBoundary } from 'react-error-boundary';
import Moment from 'react-moment';
import { useNavigate } from 'react-router-dom';

import { Chat } from 'components/Chat';
import { ControlToolBar } from 'components/ControlToolBar';
import { MeetingName } from 'components/MeetingName';
import { ParticipantList } from 'components/ParticipantList';
import { Spinner } from 'components/Spinner';
import VideoThumb from 'components/VideoThumb';
import { useAuth } from 'core/auth';
import { useMediaQuery } from 'core/mediaQuery';
import type { Participant } from 'core/openTok';
import { useChat, useParticipation, usePublisher, useSession } from 'core/openTok';
import { logger, VIDEO_THUMB_DOM_ID } from 'helpers';
import useStyles from './styles';

export function VideoRoom() {
    const { mediaQuery } = useMediaQuery();
    const { user, setUser, token, setToken } = useAuth();
    const [isSharing, setIsSharing] = useState(false);
    const [isParticipantListShone, setIsParticipantListShone] = useState(true);
    const videoContainer = useRef<HTMLDivElement | null>(null);
    const sharingContainer = useRef<HTMLDivElement | null>(null);
    const { publisher, publish, pubInitialised, deviceInfo } = usePublisher();
    const {
        session,
        createSession,
        connected,
        incomingSharing,
        refreshLayout,
        destroySession,
        setOnActiveSpeakerChangeListener,
        muteParticipant,
        muteAll,
    } = useSession({
        container: videoContainer,
        sharingContainer: sharingContainer,
    });
    const { startTime, participants } = useParticipation({
        publisher: publisher,
        session: session.current,
    });
    const { open, toggleChat, messages, sendMessage, newMessages } = useChat({
        session: session.current,
    });
    const { showBoundary } = useErrorBoundary();

    const classes = useStyles();

    const thumbMode = useMemo(() => {
        if (isSharing) {
            return 'hidden';
        }

        if (participants.length <= 1) {
            return 'alone';
        } else {
            return 'dnd';
        }
    }, [participants, isSharing]);

    const toggleAudio = useCallback(() => {
        setUser({
            ...user,
            defaultSettings: { ...user.defaultSettings, publishAudio: !user.defaultSettings.publishAudio },
        });
    }, [setUser, user]);

    const toggleVideo = useCallback(() => {
        setUser({
            ...user,
            defaultSettings: { ...user.defaultSettings, publishVideo: !user.defaultSettings.publishVideo },
        });
    }, [setUser, user]);

    const toggleSharing = useCallback(
        (value: boolean) => {
            setIsSharing(value);
        },
        [setIsSharing]
    );

    const toggleParticipantList = useCallback(() => {
        setIsParticipantListShone(!isParticipantListShone);
    }, [setIsParticipantListShone, isParticipantListShone]);

    const navigate = useNavigate();

    const endCall = useCallback(() => {
        destroySession();
        setToken((state) => ({ ...state, isRetrieveDone: true }));
        navigate('/end');
    }, [destroySession, navigate, setToken]);

    const sendChatMessage = useCallback(
        (message: string) => {
            sendMessage({ message }).catch((err: unknown) => {
                showBoundary({ code: 500, message: 'unable to send message', originalError: err });
            });
        },
        [sendMessage, showBoundary]
    );

    const handleMuteParticipant = useCallback(
        (participant: Participant) => {
            muteParticipant(participant)
                ?.then(() => logger.debug('mute participant', participant))
                .catch((err) => {
                    logger.error('error while muting participant', err);
                });
        },
        [muteParticipant]
    );

    const handleMuteAll = useCallback(() => {
        muteAll()
            ?.then(() => logger.debug('mute all participants'))
            .catch((err) => {
                logger.error('Error while trying to mute all participants', err);
            });
    }, [muteAll]);

    useEffect(() => {
        //cross-document messaging
        window.top?.postMessage('ROFIM_VISIO_VIDEO_STARTED');
        window.onmessage = (e) => {
            if (e.data === 'ROFIM_END_REQUESTED') {
                endCall();
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        return destroySession;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        createSession(token)
            ?.then((session) => logger.debug('OT Session created:', session))
            .catch((err: unknown) => {
                showBoundary({ code: 500, message: 'unable to create session', originalError: err });
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (session.current && connected && videoContainer?.current) {
            // todo It might be better to change state of this component or fix the any cast.
            publish({
                session: session.current,
                containerId: VIDEO_THUMB_DOM_ID,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                publisherOptions: {
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    ...(user.defaultSettings as any),
                    name: JSON.stringify({ firstname: user.firstname, lastname: user.lastname }),
                },
            })
                ?.then((publisher) => logger.debug('OT Publisher created:', publisher))
                .catch((err: unknown) => {
                    showBoundary({ code: 500, message: 'unable to create publisher', originalError: err });
                });

            refreshLayout();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [session, connected]);

    useEffect(() => {
        if (videoContainer.current) {
            let initial: ReturnType<typeof setTimeout>;

            const handleResize = () => {
                clearTimeout(initial);
                initial = setTimeout(refreshLayout, 50);
            };

            const resizeOberver = new ResizeObserver(handleResize);

            resizeOberver.observe(videoContainer.current);

            return () => {
                resizeOberver.disconnect();
            };
        }
    }, [refreshLayout]);

    useEffect(() => {
        if (pubInitialised) {
            refreshLayout();
        }
    }, [pubInitialised, refreshLayout]);

    useEffect(() => {
        if (publisher) {
            setOnActiveSpeakerChangeListener(publisher);
        }
    }, [publisher, setOnActiveSpeakerChangeListener]);

    useEffect(() => {
        if (publisher) {
            publisher.publishAudio(user.defaultSettings.publishAudio);
        }
    }, [publisher, user.defaultSettings.publishAudio]);

    useEffect(() => {
        if (publisher) {
            publisher.publishVideo(user.defaultSettings.publishVideo);
        }
    }, [publisher, user.defaultSettings.publishVideo]);

    return (
        <div className={classes.meetingRoom}>
            {mediaQuery.moreThan >= 768 && (
                <MeetingName
                    meetingName={
                        <>
                            {token?.title && `${token?.title} - `}
                            {token?.type?.toUpperCase()} du{' '}
                            <Moment
                                format="L"
                                date={token?.startDate}
                            />
                            &nbsp; à{' '}
                            <Moment
                                format="LT"
                                date={token?.startDate}
                            />
                        </>
                    }
                />
            )}
            {(mediaQuery.moreThan < 768 || mediaQuery.lessThan < 768) && (
                <MeetingName
                    meetingName={
                        <>
                            {token?.type?.toUpperCase()} du{' '}
                            <Moment
                                format="L"
                                date={token?.startDate}
                            />
                        </>
                    }
                />
            )}
            {(mediaQuery.moreThan < 768 || mediaQuery.lessThan < 768) && (
                <ControlToolBar
                    startTime={startTime}
                    className={classes.controlToolbar}
                    hasAudio={user.defaultSettings.publishAudio}
                    hasVideo={user.defaultSettings.publishVideo}
                    isAdmin={token.admin}
                    handleMicButtonClick={toggleAudio}
                    handleVideoButtonClick={toggleVideo}
                    handleShareClick={toggleSharing}
                    handleParticipantsClick={toggleParticipantList}
                    handleMuteAll={handleMuteAll}
                    isParticipantsShowing={isParticipantListShone}
                    handleCallEndButtonClick={endCall}
                    currentSession={session.current}
                    currentPublisher={publisher}
                    incomingSharing={incomingSharing}
                    sharingContainer={sharingContainer.current}
                    handleToggleChat={toggleChat}
                    newMessages={newMessages}
                    publisher={publisher}
                    deviceInfo={deviceInfo}
                    buttonClass={classes.container}
                />
            )}
            <div
                id="video-wrapper"
                className={classes.wrapper}
            >
                <VideoThumb
                    id={VIDEO_THUMB_DOM_ID}
                    mode={thumbMode}
                    fullWidth={!isParticipantListShone}
                    isStreaming={user.defaultSettings.publishVideo}
                />
                <div
                    id="video-container"
                    className={classes.container}
                    ref={videoContainer}
                >
                    {mediaQuery.moreThan >= 768 && (
                        <Chat
                            messages={messages}
                            open={open}
                            handleToggleChat={toggleChat}
                            sendChatMessage={sendChatMessage}
                        />
                    )}
                    <div
                        id="sharing-container"
                        className={cn(classes.sharingContainer, 'OT_ignore', {
                            visible: isSharing || incomingSharing,
                            hidden: !(isSharing || incomingSharing),
                        })}
                        ref={sharingContainer}
                    />
                </div>
                {mediaQuery.moreThan >= 768 && (
                    <div
                        className={cn(classes.participantsContainer, {
                            expanded: isParticipantListShone,
                            shrinked: !isParticipantListShone,
                        })}
                    >
                        <ParticipantList
                            token={token}
                            participants={participants}
                            streamId={publisher?.streamId as string}
                            closeClick={toggleParticipantList}
                            onMuteParticipant={handleMuteParticipant}
                        />
                    </div>
                )}
            </div>
            {!(session.current && connected) && (
                <div className={`${classes.loader} loader-container`}>
                    <Spinner />
                </div>
            )}
            {mediaQuery.moreThan >= 768 && (
                <ControlToolBar
                    startTime={startTime}
                    className={classes.controlToolbar}
                    hasAudio={user.defaultSettings.publishAudio}
                    hasVideo={user.defaultSettings.publishVideo}
                    isAdmin={token.admin}
                    handleMicButtonClick={toggleAudio}
                    handleVideoButtonClick={toggleVideo}
                    handleShareClick={toggleSharing}
                    handleParticipantsClick={toggleParticipantList}
                    handleMuteAll={handleMuteAll}
                    isParticipantsShowing={isParticipantListShone}
                    handleCallEndButtonClick={endCall}
                    currentSession={session.current}
                    currentPublisher={publisher}
                    incomingSharing={incomingSharing}
                    sharingContainer={sharingContainer.current}
                    handleToggleChat={toggleChat}
                    newMessages={newMessages}
                    publisher={publisher}
                    deviceInfo={deviceInfo}
                    buttonClass={classes.container}
                />
            )}
        </div>
    );
}
