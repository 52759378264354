import MicIcon from '@mui/icons-material/Mic';
import MicOffIcon from '@mui/icons-material/MicOff';
import type { MouseEventHandler } from 'react';

import { ControlButton } from 'components/ControlButton';

export type MicButtonProps = {
    hasAudio: boolean;
    onClick: MouseEventHandler;
};

export function MicButton({ hasAudio, onClick }: MicButtonProps) {
    return (
        <ControlButton
            onClick={onClick}
            Icon={hasAudio ? MicIcon : MicOffIcon}
            tooltip="Audio"
        />
    );
}
