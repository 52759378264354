import sound from 'assets/sound.gif';

export type AnimatedSoundIconProps = {
    className?: string;
};

export function AnimatedSoundIcon({ className }: AnimatedSoundIconProps) {
    return (
        <img
            src={sound}
            alt="speaking..."
            className={className}
        />
    );
}
