import CloseIcon from '@mui/icons-material/Close';
import Error from '@mui/icons-material/Error';
import { Button, IconButton } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Typography from '@mui/material/Typography';
import type { Dispatch, SetStateAction } from 'react';

import type { User } from 'core/auth';
import useStyles from './styles';

export type SimpleDialogProps = {
    user: User;
    setUser: Dispatch<SetStateAction<User>>;
    onClose: () => void;
    open?: boolean;
};

export const SimpleDialog = ({ user, setUser, onClose, open }: SimpleDialogProps) => {
    const classes = useStyles();
    const handleClose = () => onClose();

    const handleAccept = () => {
        setUser({ ...user, defaultSettings: { ...user.defaultSettings, publishVideo: false } });
        onClose();
    };

    return (
        <Dialog
            container={() => document.getElementById('visio-rofim')}
            onClose={handleClose}
            aria-labelledby="simple-dialog-title"
            open={!!open}
        >
            <DialogTitle id="simple-dialog-title">
                <div className={classes.dialogToolbar}>
                    <Typography sx={{ fontSize: '22px' }}>
                        <Error
                            className={classes.red}
                            sx={{ margin: '0 7px -4px 0' }}
                        />
                        Attention !
                    </Typography>
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                        size="large"
                    >
                        <CloseIcon />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={classes.labels}>
                    La qualité de votre connexion semble être dégradée. Souhaitez-vous arrêter votre vidéo pour
                    privilégier l'audio?
                </div>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    onClick={handleClose}
                >
                    Annuler
                </Button>
                <Button
                    variant="contained"
                    onClick={handleAccept}
                >
                    Accepter
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SimpleDialog;
