import type { Dimensions } from '@opentok/client';
import type { ReactNode } from 'react';
import { createContext, useContext, useMemo } from 'react';

import { logger } from 'helpers';
import type { Nilable } from 'types/helpers';
import type { OtSubscriber } from 'types/openTok';

export type OTSpeechConfig = typeof defaultOtSpeechConfig;

export type Channel = {
    id: string;
    pinned: boolean;
    inSpeech: boolean;
    speechStart: number;
    speechStartTest: number;
    speechEndTest: number;
    movingAverageAudioLevel: number;
    type: 'subscriber';
    subscriber: Nilable<OtSubscriber>;
    unsubscribeHandle?: number;
};

export type Speaker = {
    id: string;
};

export type SpeakerPosition = string | null | undefined;

type ActiveSpeakerChangeHandler = (
    subscriber: Channel[],
    positions: SpeakerPosition[],
    activeSpeakers?: number
) => void;
type MostActiveSpeakerChangeHandler = (id: string) => void;

const defaultOtSpeechConfig = {
    numberOfActiveSpeakers: 2, // Maximum Number of Active Speaker (which video should be shown)
    autoSubscription: true, // Automatically subscribe/unsubscribe to video with delay
    autoSubscriptionCallbackDelay: 1000, // Delay before calling callback (to allow time for subscription to stablize)
    unsubscribeDelay: 1000, // Delay before unsubscribing

    voiceLevelThreshold: 0.25, // Threshold for Voice Detection
    consecutiveVoiceMs: 500, // Minimum amount of consecutive voice (ms) before the speaker is considered in a speech
    consecutiveSilenceMs: 2000, // Minimum amount of consecutive silence (ms) before speaker is considered out of speech

    audioLevelPreviousWeight: 0.7, // previous value weightage for moving average computation
    audioLevelCurrentWeight: 0.3, // current value weightage for moving average computation
    audioLevelUpdateInterval: 100, // interval between updates of audio level in ms (lower = more real-time, higher = less cpu intensive
};

type OTSpeech = ReturnType<typeof setupOTSpeech>;

const OTSpeechContext = createContext({} as OTSpeech);

export type OTSpeechProviderProps = {
    value?: OTSpeech;
    children: ReactNode;
};

export function OtSpeechProvider({ value, children }: OTSpeechProviderProps) {
    const otSpeech = useMemo(() => setupOTSpeech(), []);

    return <OTSpeechContext.Provider value={value || otSpeech}>{children}</OTSpeechContext.Provider>;
}

export function useOtSpeech(): OTSpeech {
    const otSpeech = useContext(OTSpeechContext);
    if (!otSpeech) {
        throw new Error('useOtSpeech must be used in a OtSpeechProvider');
    }

    return otSpeech;
}

function setupOTSpeech(options?: OTSpeechConfig) {
    const config = {
        ...defaultOtSpeechConfig,
        ...options,
    };

    const channels: Record<string, Channel> = {};
    const rawAudioLevels: Record<string, number> = {};

    let selfCount = 0;
    let currentSpeakerOrder: Speaker[] = [];
    let positions: SpeakerPosition[] = [];
    let mostActiveSpeakerId: string | null = null;

    let onActiveSpeakerChangeListener: ActiveSpeakerChangeHandler | null = null;
    let onMostActiveSpeakerChangeListener: MostActiveSpeakerChangeHandler | null = null;

    // Set Interval to process audio level
    setInterval(() => {
        const channelIds = Object.keys(rawAudioLevels);

        for (let i = 0; i < channelIds.length; i += 1) {
            const channelId = channelIds[i];
            const rawAudioLevel = rawAudioLevels[channelId];

            // Update Audio Level
            processAudioLevel(channelId, rawAudioLevel);
        }
    }, config.audioLevelUpdateInterval);

    const isVoice = (maLevel: number) => {
        let logLevel = Math.log(maLevel) / Math.LN10 / 1.5 + 1;
        logLevel = Math.min(Math.max(logLevel, 0), 1);

        return logLevel > config.voiceLevelThreshold;
    };

    const getOrderedChannels = () =>
        Object.keys(channels)
            .sort((a, b) => {
                if (channels[a].pinned && !channels[b].pinned) {
                    // Pinned speaker A is in front
                    return -1;
                } else if (!channels[a].pinned && channels[b].pinned) {
                    // Pinned speaker B is in front
                    return 1;
                } else if (channels[a].inSpeech && channels[b].inSpeech) {
                    if (channels[a].speechStart !== channels[b].speechStart) {
                        // Whichever starts first in front
                        return channels[a].speechStart - channels[b].speechStart;
                    } else {
                        // Both starts at the same time, compare moving average audio level
                        return channels[b].movingAverageAudioLevel - channels[a].movingAverageAudioLevel; // Order from largest to smallest
                    }
                } else if (channels[a].inSpeech && !channels[b].inSpeech) {
                    // B behind A
                    return -1;
                } else if (!channels[a].inSpeech && channels[b].inSpeech) {
                    // A behind B
                    return 1;
                } else {
                    // Both also not in speech, compare moving average audio level

                    let aIndex = 1000; // Arbitrary large value, so that it is at the back
                    for (let i = 0; i < currentSpeakerOrder.length; i += 1) {
                        if (currentSpeakerOrder[i].id === a) {
                            aIndex = i;
                        }
                    }

                    let bIndex = 1001; // Arbitrary large value, so that it is at the back (larger than A default)
                    for (let i = 0; i < currentSpeakerOrder.length; i += 1) {
                        if (currentSpeakerOrder[i].id === b) {
                            bIndex = i;
                        }
                    }

                    return aIndex - bIndex; // Use previous order as sorted order
                }
            })
            .map((channelId) => ({
                ...channels[channelId],
                id: channelId,
                audioLevel: channels[channelId].movingAverageAudioLevel,
            }));

    const getPositions = (newSpeakerOrder: Speaker[]) => {
        const size = Math.min(config.numberOfActiveSpeakers - selfCount, newSpeakerOrder.length);
        const newIds = newSpeakerOrder.map((speaker) => speaker.id).slice(0, size);

        const availableSpeakers: SpeakerPosition[] = [...newIds]; // Clone array from new Ids
        let newPositions: SpeakerPosition[] = availableSpeakers.map(() => null); // Create array of same size with all values as null

        // Find stayed-on speakers to fill in new positions
        for (let i = 0; i < positions.length; i += 1) {
            const oldSpeakerId = positions[i];

            for (let j = 0; j < availableSpeakers.length; j += 1) {
                // Check if speaker exists
                if (availableSpeakers[j] === oldSpeakerId) {
                    newPositions[i] = availableSpeakers[j]; // Update new position
                    availableSpeakers[j] = null; // Mark speaker as unavailable
                    break;
                }
            }
        }

        // Fill remaing speakers
        const remainingSpeakers = availableSpeakers.filter((speaker) => speaker != null);

        for (let i = 0; i < newPositions.length; i += 1) {
            if (newPositions[i] == null) {
                // Find available
                if (remainingSpeakers.length < 1) {
                    break;
                }

                // Fill new position with top remaining speaker
                const speakerId = remainingSpeakers.shift();
                newPositions[i] = speakerId;
            }
        }

        newPositions = newPositions.filter((newPosition) => newPosition != null);

        return newPositions;
    };

    const checkActiveSpeakerChange = (forceCallback = false) => {
        const newSpeakerOrder = getOrderedChannels(); // Get sorted speakers based on speech and moving average audio level
        const newPositions = getPositions(newSpeakerOrder); // Get updated speaker positions in the grid

        const numberOfNonSelfSpeakers = config.numberOfActiveSpeakers - selfCount;
        const newSize = Math.min(numberOfNonSelfSpeakers, newSpeakerOrder.length); // Get max length for slice (against max number of active speaker)
        const oldSize = Math.min(numberOfNonSelfSpeakers, currentSpeakerOrder.length); // Get max length for slice (against max number of active speaker)

        const newIds = newSpeakerOrder
            .map((speaker) => speaker.id)
            .slice(0, newSize)
            .sort(); // Slice and sort to size
        const oldIds = currentSpeakerOrder
            .map((speaker) => speaker.id)
            .slice(0, oldSize)
            .sort(); // Slice and sort to size

        const newIdsString = JSON.stringify(newIds); // For easy comparison
        const oldIdsString = JSON.stringify(oldIds); // For easy comparison

        // Check Most Active Speaker Change
        let newMostActiveSpeakerId = null;
        let newMostActiveSpeakerAudioLevel = 0;

        for (let i = 0; i < newSpeakerOrder.length; i += 1) {
            const speaker = newSpeakerOrder[i];
            const audioLevel = speaker.audioLevel;

            if (audioLevel > newMostActiveSpeakerAudioLevel) {
                newMostActiveSpeakerId = speaker.id;
                newMostActiveSpeakerAudioLevel = audioLevel;
            }
        }
        if (newMostActiveSpeakerId !== mostActiveSpeakerId) {
            mostActiveSpeakerId = newMostActiveSpeakerId;

            if (onMostActiveSpeakerChangeListener !== null && mostActiveSpeakerId !== null) {
                onMostActiveSpeakerChangeListener(mostActiveSpeakerId);
            }
        }

        // Update position and speaker order
        positions = newPositions;
        currentSpeakerOrder = newSpeakerOrder;

        // Run only if there are changes in active speaker list
        if (forceCallback || newIdsString !== oldIdsString) {
            // Subscribe/Unsubscribe to videos
            if (config.autoSubscription) {
                updateSubscriptionToVideos();
            }

            // Callback
            if (onActiveSpeakerChangeListener != null) {
                const delay = config.autoSubscription ? config.autoSubscriptionCallbackDelay : 0; // Add delay if there is auto subscription
                setTimeout(
                    () =>
                        onActiveSpeakerChangeListener &&
                        onActiveSpeakerChangeListener(newSpeakerOrder, newPositions, config.numberOfActiveSpeakers),
                    delay
                );
            }
        }
    };

    const getMostActiveSpeakerId = () => mostActiveSpeakerId;

    const updateSubscriptionToVideos = () => {
        const resolution = {
            width: 1280,
            height: 720,
        };
        let framerate = 30;

        const currentNumberOfActiveSpeakers = Math.min(config.numberOfActiveSpeakers, currentSpeakerOrder.length);

        // Resolution
        if (currentNumberOfActiveSpeakers > 4) {
            resolution.width = 320;
            resolution.height = 240;
        } else if (currentNumberOfActiveSpeakers > 1) {
            resolution.width = 640;
            resolution.height = 480;
        } else {
            resolution.width = 1280;
            resolution.height = 720;
        }

        // Frame Rate
        if (currentNumberOfActiveSpeakers > 9) {
            framerate = 7;
        } else if (currentNumberOfActiveSpeakers > 4) {
            framerate = 15;
        } else {
            framerate = 30;
        }

        for (let i = 0; i < currentSpeakerOrder.length; i += 1) {
            const speaker = currentSpeakerOrder[i];
            const shouldSubscribeToVideo = i < config.numberOfActiveSpeakers - selfCount;
            subscribeToVideo(speaker.id, shouldSubscribeToVideo);
            subscribeToQuality(speaker.id, resolution, framerate);
        }
    };

    const getMovingAverage = (previousMovingAverage: number | null, currentAudioLevel: number) => {
        if (previousMovingAverage == null || previousMovingAverage <= currentAudioLevel) {
            return currentAudioLevel;
        } else {
            return (
                config.audioLevelPreviousWeight * previousMovingAverage +
                config.audioLevelCurrentWeight * currentAudioLevel
            );
        }
    };

    const addAudioLevel = (channelId: string, audioLevel: number) => {
        rawAudioLevels[channelId] = audioLevel;
    };

    const processAudioLevel = (channelId: string, audioLevel: number) => {
        if (channelId == null) {
            return;
        }

        if (channels[channelId] == null) {
            delete rawAudioLevels[channelId];
            logger.debug(`Channel ${channelId} does not exist`);
            return;
        }

        // Calculate Moving Average
        channels[channelId].movingAverageAudioLevel = getMovingAverage(
            channels[channelId].movingAverageAudioLevel,
            audioLevel
        );

        // Check for speech start
        const currentTime = new Date().getTime();
        const voiceDetected = isVoice(channels[channelId].movingAverageAudioLevel);

        if (voiceDetected) {
            // Voice Detected
            if (channels[channelId].inSpeech) {
                // Do nothing, already in speech
            } else if (channels[channelId].speechStartTest === 0) {
                // Has not started test for start
                channels[channelId].speechStartTest = currentTime;
            } else if (channels[channelId].speechStartTest + config.consecutiveVoiceMs < currentTime) {
                // Speech started or within speech
                channels[channelId].inSpeech = true;
                channels[channelId].speechStartTest = 0;
                // console.log('Speech Start');

                // Set Speech start time
                if (channels[channelId].speechStart === 0) {
                    channels[channelId].speechStart = currentTime;
                }
            }

            // Reset First Silence
            channels[channelId].speechEndTest = 0;
        } else {
            // Silence Detected
            if (!channels[channelId].inSpeech) {
                // Do nothing, already not
            } else if (channels[channelId].speechEndTest === 0) {
                // Has not started test for end
                channels[channelId].speechEndTest = currentTime;
            } else if (channels[channelId].speechEndTest + config.consecutiveSilenceMs < currentTime) {
                // Speech ended
                channels[channelId].inSpeech = false;
                channels[channelId].speechEndTest = 0;
                // console.log('Speech End');

                // Reset Speech start time
                channels[channelId].speechStart = 0;
            }

            // Reset First Voice
            channels[channelId].speechStartTest = 0;
        }

        // Check Active Speaker Change
        checkActiveSpeakerChange(false);
    };

    const addSelf = () => {
        selfCount += 1;
    };

    const removeSelf = () => {
        selfCount -= 1;
        if (selfCount < 0) {
            selfCount = 0;
        }
    };

    const addSubscriber = (subscriber: OtSubscriber) => {
        logger.debug(`Adding Subscriber ${subscriber.stream.id}`);

        channels[subscriber.stream.id] = {
            id: subscriber.stream.id,
            type: 'subscriber',
            subscriber,
            movingAverageAudioLevel: 0,
            speechStart: 0,
            speechStartTest: 0,
            speechEndTest: 0,
            inSpeech: false,
            pinned: false,
            unsubscribeHandle: undefined,
        };

        // Add Audio Level Event Listener
        subscriber.on('audioLevelUpdated', (e) => {
            // Add Audio Level
            addAudioLevel(subscriber.stream.id, e.audioLevel);
        });

        checkActiveSpeakerChange(true);
    };

    const removeSubscriber = (subscriber: OtSubscriber) => {
        logger.debug(`Removing Subscriber ${subscriber.stream?.id}`);

        delete channels[subscriber.stream.id];
        delete rawAudioLevels[subscriber.stream.id];

        checkActiveSpeakerChange(true);
    };

    const removeSubscriberByStreamId = (streamId: string) => {
        const subscriber = getSubscriberByStreamId(streamId);

        if (subscriber != null) {
            removeSubscriber(subscriber);
        }
    };

    const getSubscriberByStreamId = (streamId: string) => {
        const channelIds = Object.keys(channels);

        for (let i = 0; i < channelIds.length; i += 1) {
            const channelId = channelIds[i];
            const channel = channels[channelId];

            if (channel.type === 'subscriber') {
                const subscriber = channel.subscriber;

                if (subscriber?.stream == null) {
                    delete channels[channelId];
                    continue;
                }

                const subscriberStreamId = subscriber.stream.id;
                if (subscriberStreamId === streamId) {
                    return subscriber;
                }
            }
        }

        return null;
    };

    const getSubscriberIdByStreamId = (streamId: string) => {
        return (getSubscriberByStreamId(streamId) || {}).id;
    };

    const getStreamByStreamId = (streamId: string) => {
        return (getSubscriberByStreamId(streamId) || {}).stream;
    };

    const setSpeakerPin = (channelId: string, pinned: true) => {
        if (channels[channelId] != null) {
            channels[channelId].pinned = pinned;
        }

        checkActiveSpeakerChange(true);
    };

    const setOnActiveSpeakerChangeListener = (listener: ActiveSpeakerChangeHandler) => {
        onActiveSpeakerChangeListener = listener;
    };

    const setOnMostActiveSpeakerChangeListener = (listener: MostActiveSpeakerChangeHandler) => {
        onMostActiveSpeakerChangeListener = listener;
    };

    const setNumberOfActiveSpeakers = (value: number | string) => {
        logger.debug(`Setting Number of Active Speakers to ${value}`);
        config.numberOfActiveSpeakers = typeof value === 'string' ? parseInt(value, 10) : value;
        checkActiveSpeakerChange(true);
    };

    const getNumberOfActiveSpeakers = () => config.numberOfActiveSpeakers;

    const setVoiceLevelThreshold = (value: number | string) => {
        logger.debug(`Setting Voice Level Threshold to ${value}`);
        config.voiceLevelThreshold = typeof value === 'string' ? parseFloat(value) : value;
        checkActiveSpeakerChange(true);
    };

    const subscribeToVideo = (channelId: string, shouldSubscribeToVideo: boolean) => {
        if (channels[channelId].unsubscribeHandle !== undefined) {
            window.clearTimeout(channels[channelId].unsubscribeHandle);
            channels[channelId].unsubscribeHandle = undefined;
        }

        if (channels[channelId].subscriber) {
            if (shouldSubscribeToVideo && channels[channelId].subscriber?.subscribeToVideo) {
                channels[channelId].subscriber?.subscribeToVideo(true);
            } else if (channels[channelId].subscriber?.subscribeToVideo) {
                const handle = window.setTimeout(
                    () => channels[channelId].subscriber?.subscribeToVideo(false),
                    config.unsubscribeDelay
                );
                channels[channelId].unsubscribeHandle = handle;
            }
        }
    };

    const subscribeToQuality = (channelId: string, resolution: Dimensions, framerate: number) => {
        channels[channelId].subscriber?.setPreferredResolution &&
            channels[channelId].subscriber?.setPreferredResolution(resolution);
        channels[channelId].subscriber?.setPreferredFrameRate &&
            channels[channelId].subscriber?.setPreferredFrameRate(framerate);
    };

    const notifySpeakerChange = () => checkActiveSpeakerChange(true);

    return {
        // Listeners
        setOnActiveSpeakerChangeListener,
        setOnMostActiveSpeakerChangeListener,

        // Config
        setNumberOfActiveSpeakers,
        getNumberOfActiveSpeakers,
        setVoiceLevelThreshold,

        // Subscribers
        addSelf,
        removeSelf,
        addSubscriber,
        removeSubscriber,
        removeSubscriberByStreamId,
        getSubscriberIdByStreamId,
        getStreamByStreamId,

        // Action
        updateSubscriptionToVideos,
        subscribeToVideo,
        subscribeToQuality,
        addAudioLevel,
        setSpeakerPin,
        notifySpeakerChange,

        // Results
        getOrderedChannels,
        getPositions,
        getMostActiveSpeakerId,
    };
}
