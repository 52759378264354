import type { Token } from 'core/auth';
import { Navigate, Outlet } from 'react-router-dom';

export type ProtectedRouteProps = {
    token: Token;
    redirectPath?: string;
};

export function ProtectedRoute({ token, redirectPath = '/401' }: ProtectedRouteProps) {
    if (!token.token || !token.room || !token.username || !token.sessionId) {
        return (
            <Navigate
                to={redirectPath}
                replace
            />
        );
    }

    return <Outlet />;
}
