import type { Session } from '@opentok/client';
import { useCallback, useEffect, useState } from 'react';

import type { User } from 'core/auth';
import { useAuth } from 'core/auth';
import { capitalize, useAvatar } from 'helpers';
import type { Nilable } from 'types/helpers';
import type { OtPublisher, OtStream, WithSip } from 'types/openTok';

export type UseParticipationOptions = {
    session: Nilable<Session>;
    publisher: Nilable<OtPublisher>;
};

export type Participant = {
    id: string;
    firstname: string;
    lastname: string;
    avatarUrl: Nilable<string>;
};

export function useParticipation({ session, publisher }: UseParticipationOptions) {
    const { user } = useAuth();
    const { getAvatarUrl } = useAvatar();
    const [participants, setParticipants] = useState<Participant[]>([]);
    const [startTime, setStartTime] = useState<number | null>(null);

    const addParticipant = useCallback(
        (stream: OtStream) => {
            if (participants.find((p) => p.id === stream.id)) return;

            let participant: Participant;

            if (stream.name) {
                const currentParticipant: User =
                    stream.id === publisher?.stream?.id ? user : (JSON.parse(stream.name) as User);
                const firstname = capitalize(currentParticipant.firstname);
                const lastname = capitalize(currentParticipant.lastname);
                const avatarUrl = getAvatarUrl(currentParticipant.firstname, currentParticipant.lastname, true);
                participant = { id: stream.id, firstname, lastname, avatarUrl };
            } else {
                participant = {
                    id: stream.id,
                    firstname: 'Connexions',
                    lastname: 'par téléphone',
                    avatarUrl: null,
                };
            }

            setParticipants((arr) => [...arr, participant]);

            if (!startTime || startTime > stream.creationTime) {
                setStartTime(stream.creationTime);
            }
        },
        [getAvatarUrl, participants, publisher, startTime, user]
    );

    const removeParticipant = useCallback(
        (stream: OtStream) => {
            setParticipants(participants.filter((participant) => participant.id !== stream.id));
        },
        [participants]
    );

    const onStreamCreated = useCallback(
        (event: { stream: OtStream }) => {
            let isSip = false;
            try {
                const streamConnection = JSON.parse(event.stream.connection.data) as WithSip;
                isSip = !!streamConnection?.sip;
                // eslint-disable-next-line no-empty
            } catch (err: unknown) {}

            if (!isSip && !event.stream?.name) return;

            if (event.stream.videoType !== 'screen') {
                addParticipant(event.stream);
            }
        },
        [addParticipant]
    );

    const onStreamDestroyed = useCallback(
        (event: { stream: OtStream }) => {
            removeParticipant(event.stream);
        },
        [removeParticipant]
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const eventHandlers = {
        streamCreated: onStreamCreated,
        streamDestroyed: onStreamDestroyed,
    };

    useEffect(() => {
        const eventHandlers = {
            streamCreated: onStreamCreated,
            streamDestroyed: onStreamDestroyed,
        };

        if (session) {
            session.on(eventHandlers);
        }

        return function cleanup() {
            if (session) {
                session.off(eventHandlers);
            }
        };
    }, [onStreamCreated, onStreamDestroyed, session]);

    useEffect(() => {
        if (publisher) {
            publisher.on(eventHandlers);
        }

        return function cleanup() {
            if (publisher) publisher.off(eventHandlers);
        };
    }, [eventHandlers, onStreamCreated, onStreamDestroyed, publisher]);

    return { participants, startTime, eventHandlers };
}
