import cn from 'classnames';
import type { ReactNode } from 'react';
import { useMemo, useRef } from 'react';
import Draggable from 'react-draggable';

import DragIndicator from '@mui/icons-material/DragIndicator';
import useStyles from './styles';

export type VideoThumbMode = 'dnd' | 'alone' | 'hidden';

export type VideoThumbProps = {
    id?: string;
    mode?: VideoThumbMode;
    fullWidth?: boolean;
    children?: ReactNode;
    isStreaming?: boolean;
};

export default function VideoThumb({ id, mode = 'dnd', fullWidth, children, isStreaming }: Readonly<VideoThumbProps>) {
    const classes = useStyles();
    const containerRef = useRef<HTMLDivElement>(null);

    const defaultPosition = useMemo(() => ({ x: window.innerWidth - 220, y: window.innerHeight - 300 }), []);

    const containerClasses = cn(classes.container, {
        [classes.dnd]: mode === 'dnd',
        [classes.alone]: mode === 'alone',
        [classes.hidden]: mode === 'hidden',
        [classes.fullWidth]: mode === 'alone' && fullWidth,
        [classes.streaming]: isStreaming,
    });

    if (window.innerWidth === 0 || window.innerHeight === 0) {
        return null;
    }

    return (
        <Draggable
            bounds="parent"
            disabled={mode !== 'dnd'}
            defaultPosition={defaultPosition}
            defaultClassName={containerClasses}
        >
            <div
                id={id}
                ref={containerRef}
            >
                {mode === 'dnd' && <DragIndicator className={classes.icon} />}
                {children}
            </div>
        </Draggable>
    );
}
