import type { Root } from 'react-dom/client';
import { createRoot } from 'react-dom/client';
import type { Nilable } from 'types/helpers';

export * from './constants';
export * from './logger';
export * from './useAvatar';

/**
 * Render the given jsx element into the given Element
 *
 * @param jsx - The jsx element to render
 * @param element - The dom element to render the jsx into
 */
export function render(jsx: JSX.Element, element: Nilable<Element>, root?: Root) {
    if (!element) {
        throw new Error("Trying to render some jsx but the element doesn't exist");
    }

    if (root) {
        root.render(jsx);
    } else {
        createRoot(element).render(jsx);
    }
}

/**
 * Uppercase the first char of the given string
 */
export function capitalize(value: string): string {
    return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
}
