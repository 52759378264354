import { Route, Routes } from 'react-router-dom';

import { ProtectedRoute } from 'components/ProtectedRoute';
import { Spinner } from 'components/Spinner';
import { useAuth } from 'core/auth';
import { MediaQueryProvider } from 'core/mediaQuery';
import { OtSpeechProvider } from 'core/openTok';
import { SessionEnded } from 'pages/SessionEnded';
import { VideoRoom } from 'pages/VideoRoom';
import { WaitingRoom } from 'pages/WaitingRoom';

function AppRouter() {
    const { token } = useAuth();

    if (!token?.isRetrieveDone) {
        return (
            <div className="loader-container">
                <Spinner />
            </div>
        );
    }

    return (
        <OtSpeechProvider>
            <MediaQueryProvider>
                <Routes>
                    <Route element={<ProtectedRoute token={token} />}>
                        <Route
                            path="/"
                            element={<WaitingRoom />}
                        />
                        <Route
                            path="/room/:roomName"
                            element={<VideoRoom />}
                        />
                    </Route>
                    <Route
                        path="/"
                        element={<WaitingRoom />}
                    />
                    <Route
                        path="/room/:roomName"
                        element={<VideoRoom />}
                    />
                    <Route
                        path="/end"
                        element={<SessionEnded />}
                    />
                </Routes>
            </MediaQueryProvider>
        </OtSpeechProvider>
    );
}

export default AppRouter;
