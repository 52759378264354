import PeopleAltIcon from '@mui/icons-material/PeopleAlt';
import PersonOff from '@mui/icons-material/PersonOff';
import type { MouseEventHandler } from 'react';

import { ControlButton } from 'components/ControlButton';

export type ParticipantsButtonProps = {
    isParticipantsShowing: boolean;
    onClick: MouseEventHandler;
};

export function ParticipantsButton({ isParticipantsShowing, onClick }: ParticipantsButtonProps) {
    return (
        <ControlButton
            Icon={isParticipantsShowing ? PeopleAltIcon : PersonOff}
            onClick={onClick}
            tooltip="Liste des participants"
        />
    );
}
