import { Typography } from '@mui/material';
import { useMemo } from 'react';

import { ErrorPageWrapper } from 'components/ErrorPageWrapper';
import { logger } from 'helpers';

export type ErrorFallbackProps = {
    error: {
        code: 401 | 500;
        message?: string;
        originalError?: Error;
    };
};

export function ErrorFallback({ error }: ErrorFallbackProps) {
    if (error.originalError) {
        logger.error(error.originalError);
    }

    const isUnauthorized = error.code && error.code === 401;

    const title = useMemo(() => {
        if (isUnauthorized) {
            return 'Accès refusé';
        } else {
            return 'Erreur inattendue';
        }
    }, [isUnauthorized]);

    const subTitle = useMemo(() => {
        if (isUnauthorized) {
            return "Vous n'avez pas les droits nécessaires pour accéder ou la session a expiré.";
        } else {
            return 'Une erreur interne est survenue.';
        }
    }, [isUnauthorized]);

    return (
        <ErrorPageWrapper>
            <Typography
                variant="h5"
                component="h2"
            >
                {title}
            </Typography>
            <p>{subTitle}</p>
            <p>Veuillez contacter l'administrateur du site.</p>
        </ErrorPageWrapper>
    );
}
